import { useEffect, useMemo } from 'react';
import { useMap } from 'react-leaflet';
import L, { Control, Layer } from 'leaflet';
import 'leaflet-choropleth';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { MapFeatureDetails } from '@/types/feature/featureDetails';
import { FeatureCollection, Feature } from 'geojson';
import { defaultColors } from '@/tcomponents/custom/ColorPicker';
import { formatMailtoString } from '../helpers/formatMailToString';
import { useActiveLayerInteractions } from '../hooks/useActiveLayerInteractions';
import { useMapViewContext } from '../mapContext/MapViewProvider';

type Props = {
  shapeFiledata: FeatureCollection | undefined;
  layerData: MapFeatureDetails['boundaries'][0];
  dataStoreData?: any;
};

export const CategoryBoundary = (props: Props) => {
  const { shapeFiledata, layerData, dataStoreData } = props;

  const newThemingArray: { key: string; color: string }[] = [
    ...layerData.theming,
  ];
  const { setActiveBoundaryFeature, activeBoundaryFeature } =
    useMapViewContext();

  const map = useMap();

  const layerOpacity = layerData.opacity ?? 0.5;
  const {
    handleLayerHighlightOnMouseOver,
    handleLayerResetOnMouseOut,
    handleActiveLayerStyling,
    resetLayerStylesOnDeselection,
  } = useActiveLayerInteractions(layerOpacity);

  const lookupObject = useMemo(() => {
    if (!dataStoreData || !layerData.theming) return undefined
    const undefinedTheming: Record<string, string> = {};
    return dataStoreData.reduce(
      (
        acc: Record<string, string>,
        curr: { _name: string; _value: string },
        index: number
      ) => {
        const themingColor = layerData.theming.find(
          theme => theme.key === curr._value
        )?.color;
        if (themingColor) {
          acc[curr._name] = themingColor;
        } else {
          if (!undefinedTheming[curr._value] && curr._value) {
            undefinedTheming[curr._value] =
              defaultColors[index % defaultColors.length];
          }
          acc[curr._name] = undefinedTheming[curr._value];
          if (!newThemingArray.some(item => item.key === curr._value)) {
            newThemingArray.push({
              key: curr._value,
              color: undefinedTheming[curr._value],
            });
          }
        }
        return acc;
      },
      {}
    );
  }, [dataStoreData]);

  const processedShapeFileData = useMemo(() => {
    if (!shapeFiledata || !shapeFiledata.features.length || !lookupObject) return undefined;
    const processedFeatures = shapeFiledata.features.map((feature: Feature) => {
      if (!feature.properties) {
        feature.properties = {};
      }

      let colorFromBoundarySetting =
        lookupObject[feature.properties[layerData.keyProperty]];

      if (colorFromBoundarySetting && !feature.properties['categorycolor']) {
        feature.properties['categorycolor'] = colorFromBoundarySetting;
      }

      const newPropertiesToAdd = dataStoreData.find(
        (data: any) =>
          data._name === feature.properties?.[layerData.keyProperty]
      );
      if (newPropertiesToAdd) {
        const { _name, _value, ...restOfProperties } = newPropertiesToAdd;
        feature.properties = {
          ...feature.properties,
          ...restOfProperties,
        };
      }
      return feature;
    });

    return {
      ...shapeFiledata,
      features: processedFeatures,
    };
  }, [lookupObject, shapeFiledata]);

  useEffect(() => {
    if (!processedShapeFileData || !map || !lookupObject || !layerData) return;
    // @ts-ignore - there are no types available for leaflet-choropleth
    const geo = L.choropleth(processedShapeFileData, {
      style: function (feature: Feature) {
        return {
          fillColor:
            feature.properties?.categorycolor ?? layerData.color ?? '#ccc',
          weight: 1,
          color: '#ccc',
          fillOpacity: layerOpacity,
        };
      },

      onEachFeature: function (f: Feature, l: Layer) {
        l.on({
          click: () => setActiveBoundaryFeature(f),
          mouseover: handleLayerHighlightOnMouseOver,
          mouseout: handleLayerResetOnMouseOut,
        });
        if (f.properties) {
          // Getting the hoverProperties from the boundary and convert to lower case, we had to do this because in boundary properties are in different case
          const hoverPropertiesFromShapeFile =
            layerData?.hoverProperties
              ?.filter(prop => prop.label && prop.value)
              ?.map(prop => prop.value.toLowerCase()) ?? [];
          // hover properties from dataStoreData
          const hoverPropertiesFromDataStore =
            layerData?.dataStoreHoverKeys
              ?.filter(prop => prop.value)
              ?.map(prop => prop.value.toLowerCase()) ?? [];

          const finalHoverProperties = [
            ...hoverPropertiesFromShapeFile,
            ...hoverPropertiesFromDataStore,
          ];

          if (finalHoverProperties.length > 0) {
            // Create a new object with lower case keys
            const lowerCaseProperties = Object.fromEntries(
              Object.entries(f.properties).map(([key, value]) => [
                key.toLowerCase(),
                value,
              ])
            );
            const out = finalHoverProperties
              ?.filter(
                (key: string) =>
                  key in lowerCaseProperties && lowerCaseProperties[key]
              )
              ?.map(
                (key: string, index: number) =>
                  `<strong key=${index}>${
                    key.charAt(0).toUpperCase() + key.slice(1)
                  }</strong> <br />
                    ${formatMailtoString(lowerCaseProperties[key])} <br />`
              );
            if (out.length) {
              l.bindPopup(out.join('<br />'));
            }
          }
        }
      },
    }).addTo(map);

    const loadFile = () => {
      try {
        geo.addData(shapeFiledata);
        geo.bringToBack();
      } catch (err) {
        console.error(err);
        toast({
          title: 'Oh no!',
          variant: 'destructive',
          description: `Failed to load map layer file: ${layerData.title}`,
        });
      }
    };

    loadFile();

    // this logic is to handle the selection/deselection of active layer on the map
    if (activeBoundaryFeature) {
      handleActiveLayerStyling(geo);
      resetLayerStylesOnDeselection(geo);
    }

    return () => {
      map.removeLayer(geo);
    };
  }, [ layerData, activeBoundaryFeature, processedShapeFileData]);

  useEffect(() => {
    if (!shapeFiledata || !map || !dataStoreData || !layerData) return;
    const legend = new Control({ position: 'bottomright' });

    const filteredThemingArray =
      newThemingArray
        .filter(theme => theme.key)
        .sort((a, b) => String(a.key).localeCompare(String(b.key))) ?? [];
    legend.onAdd = () => {
      const div = L.DomUtil.create(
        'div',
        'legend scale-50 sm:scale-100 origin-bottom-right overflow-auto bg-white rounded-md border !mb-[20px]  !mr-[20px] p-8'
      );

      div.innerHTML = `
      <div class='flex flex-col flex-wrap gap-y-2.5 gap-x-4 max-h-[170px]'>
        ${filteredThemingArray
          ?.map((theme, index) => {
            return `
                <div key=${index} class='flex  gap-x-4'>
                    <div class='w-10 h-5 rounded-md' style='background-color: ${theme.color}'></div>
                    <p class='pr-2 sm:pr-0'>${theme.key}</p>
                </div>
                `;
          })
          .join('')}
      </div>
      `;

      return div;
    };

    legend.addTo(map);

    return () => {
      map.removeControl(legend);
    };
  }, [shapeFiledata, map, layerData, dataStoreData]);

  return null;
};
