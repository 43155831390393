import { ChangeEvent } from 'react';
import { Label } from '@/tcomponents/ui/label';
import { ContentInfo, Info } from '../Helpers/Types';
import CustomImageField from './CustomImageField';
import { Switch } from '@/tcomponents/ui/switch';
import { useSurveyContext } from '../SurveyProvider';
import { useUploadSurveyAssetMutation } from '@/store/services/projects/surveyApi';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { v4 as uuid } from 'uuid';
import CustomMarkdownEditor from '@/tcomponents/custom/CustomMarkdownEditor';

type ContentInfoProps = {
  info: ContentInfo;
  updateStep: (info: Info) => void;
};

export const ContentInfoCard = (props: ContentInfoProps) => {
  const { info, updateStep } = props;
  const { surveyConfigId } = useSurveyContext();
  const [uploadSurveyAsset] = useUploadSurveyAssetMutation();

  const handleImageUpload = (
    e: ChangeEvent<HTMLInputElement>,
    file: Blob | string
  ) => {
    e.preventDefault();
    let formBody = new FormData();
    formBody.append('id', surveyConfigId?.toString() ?? '');
    formBody.append('type', `info-${info.id}-${uuid()}`);
    formBody.append('asset', file);

    uploadSurveyAsset(formBody)
      .unwrap()
      .then(response => {
        toast({
          duration: 3000,
          description: 'Uploaded the image',
        });
        updateStep({
          ...info,
          image: response.path,
        });
      })
      .catch(() => {
        toast({
          duration: 24 * 60 * 60000,
          className: 'bg-red-500',
          variant: 'destructive',
          title: 'Uh oh!',
          description: 'Could not upload the image',
        });
        updateStep({
          ...info,
          image: '',
        });
      });
  };

  const handleImageRemove = () => {
    updateStep({
      ...info,
      image: '',
    });
  };

  return (
    <div className="flex flex-col py-2 gap-y-6">
      <div className="flex gap-x-8">
        <div className="flex flex-col gap-y-1">
          <Label>Header Image</Label>
          <CustomImageField
            image={info.image ?? ''}
            handleImageUpload={handleImageUpload}
            handleImageRemove={handleImageRemove}
            className="w-[400px] h-[150px]"
            maxFileSize={1}
          />
        </div>
        <div className="flex flex-col gap-y-1">
          <Label>Crop Image To Fit</Label>
          <Switch
            checked={info.crop ?? false}
            onCheckedChange={val => {
              const newInfo = {
                ...info,
                crop: val,
              };
              updateStep(newInfo);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col w-full bg-white rounded-md shadow-sm gap-y-1 min-h-[250px]">
        <Label>Body Content</Label>
        <CustomMarkdownEditor
          markdown={info.body ?? ''}
          onChange={value => {
            const newInfo = {
              ...info,
              body: value,
            };
            updateStep(newInfo);
          }}
          className="grow"
          placeholder="Enter body content here"
        />
      </div>
    </div>
  );
};
