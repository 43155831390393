import React, { FormEvent, ReactElement } from 'react';
import { useCustomGraphContext } from '../../CustomGraphProvider';
import Field from '@/pages/dashboard/projects/graph/nodes/form-fields/Field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@/tcomponents/ui/button';
import Mappable from '@/pages/dashboard/projects/graph/types/mappable';
import { Input } from '@tcomponents/ui/input';

const ValueMapFormFields = (): ReactElement => {
  const { updateNodeData, activeNode } = useCustomGraphContext();

  const addValue = (): void => {
    const data = {
      ...activeNode.data,
      values: [...activeNode.data.values],
    };

    data.values.push({
      input: '',
      output: '',
    });

    updateNodeData(data);
  };

  const updateValue = (index: number, value: Mappable): void => {
    const data = {
      ...activeNode.data,
      values: [...activeNode.data.values],
    };

    data.values[index] = value;

    updateNodeData(data);
  };

  const deleteValue = (index: number): void => {
    const data = {
      ...activeNode.data,
      values: [...activeNode.data.values],
    };

    data.values = data.values.filter(
      (_: Mappable, i: number): boolean => i !== index
    );

    updateNodeData(data);
  };

  const updateField = ({ target }: FormEvent<HTMLInputElement>): void => {
    updateNodeData({
      ...activeNode.data,
      field: (target as HTMLInputElement).value,
    });
  };

  return (
    <div className="flex flex-col p-2 space-y-2">
      <div className="flex flex-col gap-2 border p-4 shadow-md bg-white rounded-md">
        <h5 className="col-span-3 border-b pb-2">Field</h5>

        <label>
          <span className="text-xs font-bold">Field</span>

          <Input value={activeNode.data.field} onInput={updateField} />
        </label>
      </div>

      <div className="flex flex-col gap-2 border p-4 shadow-md bg-white rounded-md">
        <div className="grid grid-cols-[repeat(2,_minmax(0,_1fr))_40px] gap-x-4 gap-y-2 border-b pb-2">
          <h5 className="col-span-3 border-b pb-2">Values</h5>

          <div className="text-xs font-bold">Old Value</div>

          <div className="text-xs font-bold">New Value</div>
        </div>

        {activeNode.data.values.map((value: Mappable, index: number) => (
          <Field
            key={index}
            field={value}
            onChange={v => updateValue(index, v)}
            onDelete={() => deleteValue(index)}
          />
        ))}

        <Button
          variant="info"
          className="text-white flex items-center justify-center space-x-2"
          onClick={addValue}
        >
          <FontAwesomeIcon icon={faPlus} />

          <span>New Value</span>
        </Button>
      </div>
    </div>
  );
};

export default ValueMapFormFields;
