import React, { FC, ReactNode } from 'react';
import SidebarMenuItem from './SidebarMenuItem';
import useAuth from '@/hooks/UseAuth';
import {
  aiWizardPermissions,
  mapLayersPermissions,
  organisationPermissions,
  projectPermissions,
  rolePermissions,
  servicePermissions,
  surveyPermissions,
  userPermissions,
  workflowPermissions,
} from '@/stringConsts/permissionConsts';
import { PAGES } from '@/stringConsts/pagesConst';

interface ISidebarMenuProps {
  children?: ReactNode;
  setIsMobileSidebarOpen?: (isOpen: boolean) => void;
}

const SidebarMenu: FC<ISidebarMenuProps> = ({ children }) => {
  const { hasPermission, hasAnyPermission } = useAuth();

  return (
    <ul className=" flex flex-col gap-y-[20px] text-sidebar-groupHeader grow overflow-auto pl-[25px] pr-[15px]">
      <li>
        <div className="flex flex-col gap-y-1">
          <h5>Project Management</h5>
          <div className="flex flex-col">
            <ul>
              <SidebarMenuItem
                name={'My Projects'}
                link={'/projects'}
                icon="fas fa-id-card"
              />
            </ul>
          </div>
        </div>
      </li>
      {(hasPermission(servicePermissions.print_and_post.read) ||
        hasPermission(servicePermissions.address_lookup.read) ||
        hasPermission(aiWizardPermissions.general.read)) && (
        <li>
          <div className="flex flex-col gap-y-1">
            <h5>Services</h5>
            <div className="flex flex-col">
              <ul>
                {hasPermission(servicePermissions.print_and_post.read) && (
                  <SidebarMenuItem
                    name={'Print & Post'}
                    link={'https://print.cavendishconsulting.com/'}
                    icon="fas fa-print"
                    target="_blank"
                  />
                )}
                {hasPermission(servicePermissions.address_lookup.read) && (
                  <SidebarMenuItem
                    name={'Address Lookup Map'}
                    link={'/address-lookup-map'}
                    icon="fas fa-map-location-dot"
                  />
                )}
                {hasPermission(aiWizardPermissions.general.read) && (
                  <SidebarMenuItem
                    name={'AI Wizard'}
                    link={'/ai-wizard'}
                    icon="fas fa-brain-circuit"
                  />
                )}
              </ul>
            </div>
          </div>
        </li>
      )}
      {hasAnyPermission([
        userPermissions.general.read,
        rolePermissions.general.read,
        workflowPermissions.system_administration.integrations,
        surveyPermissions.system_administration.manage_packages,
        mapLayersPermissions.general.read,
        organisationPermissions.general.read,
        projectPermissions.system_administration.manage_projects,
      ]) && (
        <li>
          <div className="flex flex-col gap-y-1">
            <h5>Administration</h5>
            <div className="flex flex-col">
              <ul>
                {hasPermission(
                  projectPermissions.system_administration.manage_projects
                ) && (
                  <SidebarMenuItem
                    name={'Projects'}
                    link={'/administration/projects'}
                    icon="fas fa-address-card"
                  />
                )}
                {hasPermission(userPermissions.general.read) && (
                  <SidebarMenuItem
                    name={'Users'}
                    link={'/users'}
                    icon="fas fa-users"
                  />
                )}
                {hasPermission(rolePermissions.general.read) && (
                  <SidebarMenuItem
                    name={PAGES.Administration.Roles}
                    link={'/roles'}
                    icon="fas fa-file-shield"
                  />
                )}
                {hasPermission(organisationPermissions.general.read) && (
                  <SidebarMenuItem
                    name={PAGES.Administration.Organisations}
                    link={'/organisations'}
                    icon="fas fa-building-columns"
                  />
                )}
                {hasPermission(
                  workflowPermissions.system_administration.integrations
                ) && (
                  <SidebarMenuItem
                    name={'Integrations'}
                    link={'/integrations'}
                    icon="fas fa-key-skeleton-left-right"
                  />
                )}
                {hasPermission(
                  surveyPermissions.system_administration.manage_packages
                ) && (
                  <SidebarMenuItem
                    name={'Surveys'}
                    link={'/surveys'}
                    icon="fas fa-clipboard-list-check"
                  />
                )}
                {hasPermission(mapLayersPermissions.general.read) && (
                  <SidebarMenuItem
                    name={'Maps'}
                    link={'/map-manager'}
                    icon="fas fa-map-location-dot"
                  />
                )}
              </ul>
            </div>
          </div>
        </li>
      )}
      {hasAnyPermission([
        workflowPermissions.system_administration.monitor,
        userPermissions.system_administration.audit_trail,
        aiWizardPermissions.system_administration.monitor,
      ]) && (
        <li>
          <div className="flex flex-col gap-y-1">
            <h5>Monitoring</h5>
            <ul>
              <li>
                <div className="flex flex-col">
                  {hasPermission(
                    workflowPermissions.system_administration.monitor
                  ) && (
                    <SidebarMenuItem
                      name={'Workflow Health'}
                      link={'/workflow-health'}
                      icon="fas fa-sitemap fa-flip-vertical"
                    />
                  )}
                </div>
              </li>
              <li>
                <div className="flex flex-col">
                  {hasPermission(
                    userPermissions.system_administration.audit_trail
                  ) && (
                    <SidebarMenuItem
                      name={'Audit Trail'}
                      link={'/audit-trail'}
                      icon="fas fa-list-alt"
                    />
                  )}
                </div>
                {hasPermission(
                  aiWizardPermissions.system_administration.monitor
                ) && (
                  <div className="flex flex-col">
                    <SidebarMenuItem
                      name={'AI Resources'}
                      link={'/ai-resources'}
                      icon="fas fa-brain-circuit"
                    />
                  </div>
                )}
              </li>
            </ul>
          </div>
        </li>
      )}
    </ul>
  );
};

export default SidebarMenu;
