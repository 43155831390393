import {
  MapContainer,
  TileLayer,
  LayersControl,
  ZoomControl,
} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import MapSideBar from './components/MapSideBar';
import Markers from './components/Markers';
import { createCustomClusterIcon } from './components/CustomCluster';
import { config } from './config';
import FeatureSourceBar, {
  FeatureSourceBarProps,
} from '../common/FeatureSourceBar';
import { ChangeView } from './components/ChangeView';
import OverlayRender from './components/OverlayRender';
import { BoundaryRenderer } from './components/BoundaryRenderer';
import { MapSearchBar } from './components/MapSearchBar';
import { MapEvents } from './components/MapEvents';
import { useMapViewContext } from './mapContext/MapViewProvider';
import ResetZoomControl from './components/ResetZoomControl';

export default function MapView() {
  const {
    setCurrentZoom,
    mapConfigResponse,
    information,
    sources,
    initialCoordsForMap,
    markersData,
    shouldShowMarkers,
    shouldShowSearchBar,
    cluster,
    overlays,
    boundaries,
    isPublicView,
    project,
    feature,
  } = useMapViewContext();

  return (
    <>
      <div className="flex w-full h-full overflow-x-hidden">
        <MapContainer {...config.settings} className="z-10 w-full h-full">
          <MapEvents setCurrentZoom={setCurrentZoom} />
          <ChangeView coordinates={initialCoordsForMap} />
          <LayersControl position="topleft">
            {config.layers.map((layer, index) => {
              return (
                <LayersControl.BaseLayer
                  key={index}
                  checked={index === 0 ? true : false}
                  name={layer.name}
                >
                  <TileLayer attribution={layer.attribution} url={layer.url} />
                </LayersControl.BaseLayer>
              );
            })}
          </LayersControl>
          <ZoomControl position="topright" zoomInText="+" zoomOutText="-" />
          <ResetZoomControl />
          {shouldShowMarkers &&
            (cluster !== false ? (
              <MarkerClusterGroup
                chunkedLoading
                iconCreateFunction={createCustomClusterIcon}
                maxClusterRadius={10}
              >
                <Markers data={markersData} />
              </MarkerClusterGroup>
            ) : (
              <Markers data={markersData} />
            ))}
          {overlays?.map(overlay => (
            <OverlayRender key={overlay.id} overlay={overlay} />
          ))}
          {boundaries?.map(boundary => (
            <BoundaryRenderer key={boundary.id} boundary={boundary} />
          ))}
          {shouldShowSearchBar && mapConfigResponse && <MapSearchBar />}
        </MapContainer>
        {!isPublicView && mapConfigResponse && <MapSideBar />}
      </div>
      <FeatureSourceBar
        information={information as string}
        sources={sources as FeatureSourceBarProps['sources']}
        project={project}
        feature={feature}
      />
    </>
  );
}
