import { LatLngExpression, latLngBounds } from 'leaflet';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useMapViewContext } from '../mapContext/MapViewProvider';

type TChangeViewProps = {
  coordinates: LatLngExpression[];
};

export const ChangeView = (props: TChangeViewProps) => {
  const { coordinates } = props;
  const { setInitialMapBoundsOnLoad } = useMapViewContext();

  const filteredCoords = coordinates.filter((coord: LatLngExpression) => {
    if (Array.isArray(coord)) {
      return coord[0] && coord[1];
    }
    return false;
  });

  const map = useMap();
  const bounds = latLngBounds(filteredCoords);

  useEffect(() => {
    if (map && filteredCoords.length) {
      map.flyToBounds(bounds, {
        maxZoom: 12,
        duration: 1,
      });
    }
    if (bounds.isValid() && filteredCoords.length) {
      setInitialMapBoundsOnLoad(bounds);
    }
  }, [map, filteredCoords.length]);
  return null;
};
