import { useGetProjectAndFeatureDetails } from '@/hooks/useGetProjectAndFeatureDetails';
import { createContext, useContext, useMemo, useState } from 'react';
import { useHandleFormContent } from './hooks/useHandleFormContent';
import { MapFormStateType, defaultMapFormState } from './helpers/formStates';
import { MapFeatureDetails } from '@/types/feature/featureDetails';
import { useGetAllShapesQuery } from '@/store/services/mapManagerApi';
import { LayerFromServer } from './types/shape';
import { useParams } from 'react-router-dom';
import {
  useGetDataStoresQuery,
  useGetRawDataFromDataStoreQuery,
  useGetWorkflowsQuery,
} from '@/store/services/projects/chartApi';
import { getKeys } from '../utils/helperFunctions';

interface MapSettingsProviderProps {
  children: React.ReactNode;
}

const defaultContextValue = {
  projectDetails: null,
  featureDetails: null,
  pageTitle: '',
  pageSubTitle: '',
  formContent: defaultMapFormState,
  setFormContent: () => {},
  errors: { nameError: '' },
  setErrors: () => {},
  allShapesResponse: { data: [] as LayerFromServer[] },
  workflowData: [],
  dataStoresOptionsMarkersTab: undefined,
  allParsedDataKeysForMarkersTab: [],
  finalDataStoreDataForMarkersTab: undefined,
  selectedWorkflowInMarkersTab: '',
  selectedDataStoreInMarkersTab: '',
};

interface IMapSettingsContext {
  projectDetails: any;
  featureDetails: any;
  pageTitle: string;
  pageSubTitle: string;
  formContent: MapFormStateType;
  setFormContent: React.Dispatch<React.SetStateAction<MapFormStateType>>;
  errors: Record<string, string>;
  setErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  allShapesResponse?: { data: LayerFromServer[] };
  workflowData: { text: string; value: string }[];
  dataStoresOptionsMarkersTab: any;
  allParsedDataKeysForMarkersTab: string[];
  finalDataStoreDataForMarkersTab: any;
  selectedWorkflowInMarkersTab: string;
  selectedDataStoreInMarkersTab: string;
}

const MapSettingsContext =
  createContext<IMapSettingsContext>(defaultContextValue);

const MapSettingsProvider = (props: MapSettingsProviderProps) => {
  const { projectId } = useParams();
  const [errors, setErrors] = useState<Record<string, string>>({
    nameError: '',
  });
  const { projectDetails, pageTitle, pageSubTitle, featureDetails } =
    useGetProjectAndFeatureDetails();

  const { formContent, setFormContent } = useHandleFormContent(
    featureDetails as MapFeatureDetails
  );
  const { data: allShapesResponse } = useGetAllShapesQuery();

  // logic related to markers tab, we need this info in boundary tab to fill the options in the Data Store Marker Field
  const workflows = useGetWorkflowsQuery(parseInt(projectId as string), {
    skip: !projectId,
  });
  const workflowData = workflows.data?.data;

  const selectedWorkflowInMarkersTab =
    formContent?.markers.data_source?.workflow;
  const dataStoresOptionsMarkersTab = useGetDataStoresQuery(
    parseInt(selectedWorkflowInMarkersTab as string),
    { skip: !selectedWorkflowInMarkersTab }
  );
  const selectedDataStoreInMarkersTab =
    formContent?.markers.data_source?.data_store;

  const dataStoreDataInMarkersTab = useGetRawDataFromDataStoreQuery(
    selectedDataStoreInMarkersTab as string,
    {
      skip: !selectedDataStoreInMarkersTab,
      refetchOnMountOrArgChange: true,
    }
  );
  const finalDataStoreDataForMarkersTab = dataStoreDataInMarkersTab.data?.data;
  // All data keys with underscore
  const allParsedDataKeysForMarkersTab = getKeys(
    finalDataStoreDataForMarkersTab,
    true
  ).sort();

  const context = useMemo(() => {
    return {
      projectDetails,
      featureDetails,
      pageTitle,
      pageSubTitle,
      formContent,
      setFormContent,
      errors,
      setErrors,
      allShapesResponse,
      workflowData,
      dataStoresOptionsMarkersTab,
      allParsedDataKeysForMarkersTab,
      finalDataStoreDataForMarkersTab,
      selectedWorkflowInMarkersTab,
      selectedDataStoreInMarkersTab,
    };
  }, [
    projectDetails,
    featureDetails,
    pageTitle,
    pageSubTitle,
    formContent,
    setFormContent,
    errors,
    allShapesResponse,
    workflowData,
    dataStoresOptionsMarkersTab,
    allParsedDataKeysForMarkersTab,
  ]);

  return (
    <MapSettingsContext.Provider value={context}>
      {props.children}
    </MapSettingsContext.Provider>
  );
};
export const useMapSettingsContext = () => {
  const context = useContext(MapSettingsContext);
  if (!context) {
    throw new Error(
      'useMapSettingsContext must be used within a SurveyProvider'
    );
  }
  return context;
};
export default MapSettingsProvider;
