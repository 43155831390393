const unparsedWarning = (name: string): string =>
  name + 'could not parse a value, so it returned the value as is';

export const boolAsYesNo = (value: any) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  } else {
    console.warn(unparsedWarning('boolAsYesNo'), value);
    return value;
  }
};

export const stringArrayAsCommaValues = (value: any) => {
  if (Array.isArray(value) && value.every(i => typeof i === 'string')) {
    return value.join(', ');
  } else {
    console.warn(unparsedWarning('stringArrayAsCommaValues'), value);
    return value;
  }
};

/**
 *
 * @param value - the value from tabulator cell
 * @param data - one may think this is unused and therefore ok to remove, but it is really not,
 *               this function is used as a callback to Tabulator internals so it needs arguments in this order
 * @param type - same as data
 * @param params - to match {'key': 'dot.notation.used'}
 */
export const objectArrayToCsv = (
  value: any,
  data: any,
  type: any,
  params: any
) => {
  let toReturn;
  try {
    toReturn = value
      .map((obj: any) => {
        let structure = params.key.split('.'),
          length = structure.length,
          output;

        for (let i = 0; i < length; i++) {
          obj = obj[structure[i]];
          output = obj;
          if (!obj) {
            break;
          }
        }
        return output;
      })
      .join(', ');
  } catch (error) {
    console.warn(unparsedWarning('objectArrayToCsv'), value);
    toReturn = value;
  }
  return toReturn;
};
