import { LayerEvent } from 'leaflet';
import { useMapViewContext } from '../mapContext/MapViewProvider';
import { Feature } from 'geojson';
import { useMap } from 'react-leaflet';
export interface CustomBoundaryLayerTypes {
  feature: Feature;
  getBounds: () => any;
  setStyle: (style: any) => void;
}

export const useActiveLayerInteractions = (layerOpacity: number) => {
  const { activeBoundaryFeature, setActiveBoundaryFeature } =
    useMapViewContext();
  const map = useMap();
  const handleLayerHighlightOnMouseOver = (e: LayerEvent) => {
    const clickedLayer = e.target;
    const layerId = clickedLayer.feature.properties.uuid; // Use unique ID
    if (!activeBoundaryFeature) {
      clickedLayer.setStyle({
        weight: 3,
        fillOpacity: layerOpacity + 0.1,
        color: '#A9A9A9',
      });
    } else if (activeBoundaryFeature.properties?.uuid !== layerId) {
      clickedLayer.setStyle({
        weight: 3,
        fillOpacity: 0.1,
        color: '#A9A9A9',
      });
    }
  };

  const handleLayerResetOnMouseOut = (e: LayerEvent) => {
    const clickedLayer = e.target;
    const layerId = clickedLayer.feature.properties.uuid; // Use unique ID
    if (!activeBoundaryFeature) {
      clickedLayer.setStyle({
        weight: 1,
        fillOpacity: layerOpacity,
        color: '#ccc',
      });
    } else if (activeBoundaryFeature.properties?.uuid !== layerId) {
      clickedLayer.setStyle({
        weight: 1,
        fillOpacity: 0.1,
        color: '#ccc',
      });
    }
  };

  const handleActiveLayerStyling = (geo: any) => {
    geo.eachLayer((layer: CustomBoundaryLayerTypes) => {
      const selectedLayer =
        layer.feature.properties?.uuid ===
        activeBoundaryFeature?.properties?.uuid;
      if (selectedLayer) {
        map.flyToBounds(layer.getBounds());
        layer.setStyle({
          fillOpacity: layerOpacity + 0.1,
          weight: 3,
          color: '#A9A9A9',
        });
      } else {
        layer.setStyle({
          fillOpacity: 0.1,
          color: '#ccc',
          weight: 1,
        });
      }
    });
  };

  const resetLayerStylesOnDeselection = (geo: any) => {
    map.on('click', function (e: any) {
      if (!e.originalEvent.target?.closest('.leaflet-interactive')) {
        setActiveBoundaryFeature(undefined);
        geo.eachLayer((layerItem: L.Layer) => {
          const currentLayer = layerItem as L.Path;
          currentLayer.setStyle({
            fillOpacity: layerOpacity,
            color: '#ccc',
            weight: 1,
          });
        });
      }
    });
  };

  return {
    handleLayerHighlightOnMouseOver,
    handleLayerResetOnMouseOut,
    handleActiveLayerStyling,
    resetLayerStylesOnDeselection,
  };
};
