import { Card, CardContent, CardHeader, CardTitle } from '@tcomponents/ui/card';
import { Input } from '@tcomponents/ui/input';
import { Textarea } from '@tcomponents/ui/textarea';
import { useSurveyContext } from '../SurveyProvider';
import { Label } from '@/tcomponents/ui/label';
import { useSaveActionContext } from '@/context/SaveActionContext';
import { handlePrependHttps } from '@/lib/utils';
import { InfoPopover } from '@/tcomponents/custom/InfoPopover';
import thanksPageInfoImage from '@/assets/images/surveyInfoImages/thanks_page.png';
import thanksMoreInfoImage from '@/assets/images/surveyInfoImages/thanks_more.png';
import { Switch } from '@/tcomponents/ui/switch';

const ThanksTab: React.FC = () => {
  const { formContent, setFormContent } = useSurveyContext();
  const { setIsDirty } = useSaveActionContext();
  return (
    <div className="flex w-full space-between gap-x-2">
      <Card className="w-[49%] h-fit">
        <CardHeader>
          <div className="flex gap-x-3">
            <CardTitle>Page Content</CardTitle>
            <InfoPopover>
              <div>
                <h6 className="mb-1">Example Thanks Page</h6>
                <img alt="Example Thanks Page" src={thanksPageInfoImage} />
              </div>
            </InfoPopover>
          </div>
          <h6 className="text-gray-400 ">
            Final page to show the user what to do next
          </h6>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex flex-col gap-y-2">
              <Label>Title</Label>
              <Input
                value={formContent.thanks.page_content.title}
                defaultValue="Thanks for taking part"
                onChange={e => {
                  const obj = { ...formContent };
                  obj.thanks.page_content.title = e.target.value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              />
            </div>
            <div className="flex flex-col gap-y-2">
              <Label>Statement</Label>
              <Textarea
                value={formContent.thanks.page_content.statement}
                onChange={e => {
                  const obj = { ...formContent };
                  obj.thanks.page_content.statement = e.target.value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              />
            </div>
          </div>
        </CardContent>
      </Card>

      <Card className="w-[49%] h-fit">
        <CardHeader>
          <div className="flex gap-x-3">
            <CardTitle>Find Out More</CardTitle>
            <InfoPopover>
              <div>
                <h6 className="mb-1">Example find out more button</h6>
                <img
                  alt="Example find out more button"
                  src={thanksMoreInfoImage}
                />
              </div>
            </InfoPopover>
          </div>
          <h6 className="text-gray-400 ">
            Optional links to external websites
          </h6>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex flex-col gap-y-2">
              <Label>Statement</Label>
              <Textarea
                value={formContent.thanks.find_out_more.statement}
                onChange={e => {
                  const obj = { ...formContent };
                  obj.thanks.find_out_more.statement = e.target.value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              />
            </div>
            <div className="flex flex-col gap-y-2">
              <Label>Link URL</Label>
              <Input
                value={formContent.thanks.find_out_more.link_url}
                onChange={e => {
                  const obj = { ...formContent };
                  obj.thanks.find_out_more.link_url = e.target.value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
                onBlur={e => {
                  let value = handlePrependHttps(e.target.value);
                  const obj = { ...formContent };
                  obj.thanks.find_out_more.link_url = value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              />
            </div>
            <div className="flex flex-col gap-y-1">
              <Label>Show Social Share Button</Label>
              <Switch
                checked={formContent.thanks.find_out_more.share}
                onCheckedChange={val => {
                  const obj = { ...formContent };
                  obj.thanks.find_out_more.share = val;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ThanksTab;
