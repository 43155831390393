import ProtectedRoute from '@/routes/ProtectedRoute';
import { projectPermissions } from '@/stringConsts/permissionConsts';
import React, { lazy } from 'react';

const AdministrationProjectsList = lazy(
  () => import('../../pages/dashboard/projects/administrationProjects/List')
);

export const projectManagementRoutes = [
  {
    element: (
      <ProtectedRoute
        permissions={[projectPermissions.system_administration.manage_projects]}
      />
    ),
    children: [
      {
        path: 'administration/projects',
        element: <AdministrationProjectsList />,
      },
    ],
  },
];
