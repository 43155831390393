import React, { FC, useContext, useEffect, useState } from 'react';
import AuthenticatedLayout from '../../../layout/AuthenticatedLayout';
import LinkInterface from '../../../types/interfaces/LinkInterface';
import { Button } from '@/tcomponents/ui/button';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProjectSettingsQuery } from '@/store/services/projects/projectApi';
import ProjectResponseInterface from '../../../types/interfaces/api-response/ProjectResponseInterface';
import { useGetFeatureQuery } from '@/store/services/projects/featureApi';
import SidebarTypes from '../../../types/enums/common/SidebarTypes';
import useAuth from '@/hooks/UseAuth';
import { toast } from '@/tcomponents/ui/toast/use-toast';

import ChartDashboard from '../projects/chartDashboard/ChartDashboard';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { useGetWidgetsData } from '../projects/chartDashboard/hooks/useGetWidgetsData';
import { NavigationContext } from '@/context/NavigationContext';
import RankedListDisplay from '../projects/rankedList/RankedListDisplay';
import { formatDateAndTime } from '../projects/utils/helperFunctions';
import { faExpand, faFileArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { FullscreenContext } from '@/context/FullscreenContext';
import ReactGA from 'react-ga4';
import TableDisplay from '@/pages/dashboard/projects/table/TableDisplay';
import MapView from '../projects/maps/MapView';
import { useLazyDispatchExportEventQuery } from '@/store/services/generalApi';
import {
  DashboardFeatureDetails,
  FeatureDetails,
  FeatureSubtypeFe,
  MapFeatureDetails,
} from '@/types/feature/featureDetails';
import TabbedNavigationBarElement from '@/layout/tab-navigation/TabbedNavigationBarElement';
import { WorkflowView } from '../projects/graph/components/WorkflowView';
import { useGetRowTypeDataStoresQuery } from '@/store/services/projects/graphApi';
import { featurePermissions } from '@/stringConsts/permissionConsts';
import { isApiErrorResponse } from '@/utils/isApiErrorResponse';
import MapViewProvider from '../projects/maps/mapContext/MapViewProvider';

interface IFeatureDetailsProps {}

const FeatureView: FC<IFeatureDetailsProps> = () => {
  const { setActivePage, setIsPublicView } = useContext(NavigationContext);
  let { featureId, featureGroupId, projectId, featureType } = useParams();
  // here we are getting the feature subtype from the react router params as featureType, so we need to give it proper types and name to make it more clear and typesafe
  const featureSubtype = featureType?.toLowerCase() as FeatureSubtypeFe;
  const featureTypeForNavigation =
    featureType === 'Ranked List' ? 'ranked-list' : featureType;
  const [tabIndex, setTabIndex] = useState(0);
  const [pageTitle, setPageTitle] = useState(
    featureId !== 'new' ? 'Loading...' : 'New Feature'
  );
  const [pageSubTitle, setPageSubTitle] = useState('');
  const [projectDetails, setProjectDetails] =
    useState<ProjectResponseInterface>();

  const [featureDetails, setFeatureDetails] = useState<FeatureDetails>();
  const [lastUpdatedForRankedList, setLastUpdatedForRankedList] =
    useState<string>('');

  const navigate = useNavigate();
  const { hasPermission } = useAuth();

  const { lastUpdated } = useGetWidgetsData();

  const project = useGetProjectSettingsQuery(Number(projectId), {
    skip: !projectId,
  });

  const feature = useGetFeatureQuery(Number(featureId), {
    skip: featureId === 'new' || !featureId,
  });
  const { handle, enterFullscreen } = useContext(FullscreenContext);

  const { data: rowTypeDataStoresForWorkflowView } =
    useGetRowTypeDataStoresQuery(Number(featureId), {
      skip: featureId === 'new' || !featureId || featureSubtype !== 'workflow',
    });
  const selectedRowTypeDataStore = rowTypeDataStoresForWorkflowView?.[tabIndex];

  // useEffect for fetching project details
  useEffect(() => {
    if (projectId && project.isSuccess) {
      setProjectDetails(project.data.data);
    }
    if (projectId && project.isError) {
      toast({
        title: 'Uh oh! Something went wrong',
        variant: 'destructive',
        description: 'There was a problem loading project details',
        duration: 24 * 60 * 60000,
      });

      navigate('/not-found');
    }
  }, [projectId, project]);

  // useEffect for fetching feature details
  useEffect(() => {
    if (featureId !== 'new' && feature.isSuccess) {
      setPageTitle(feature.data.data.name ?? '');
      setPageSubTitle(feature.data.data.header_info ?? '');
      setActivePage(feature.data.data.name);
      setFeatureDetails(feature.data.data);

      if (
        typeof feature.data.data.name !== 'undefined' &&
        typeof projectDetails?.name !== 'undefined'
      ) {
        ReactGA.event('feature_view', {
          feature_name: feature.data.data.name,
          feature_type: featureType,
          project_name: projectDetails?.name,
        });
      }
    }
    if (featureId !== 'new' && feature.isError) {
      toast({
        title: 'Uh oh! Something went wrong',
        variant: 'destructive',
        description: 'There was a problem loading feature details',
        duration: 24 * 60 * 60000,
      });
      if (
        isApiErrorResponse(feature?.error) &&
        feature?.error?.status !== 403
      ) {
        navigate('/not-found');
      } else {
        navigate('/unauthorized');
      }
    }
  }, [featureId, feature]);

  // useEffect to set isPublicView true on initial load
  useEffect(() => {
    setIsPublicView(true);
  }, []);

  const breadcrumbElements: Array<LinkInterface> =
    featureId !== 'new'
      ? [
          {
            displayName: 'Projects',
            link: '/projects',
          },
          {
            displayName: projectDetails?.name ?? '',
            link: `/projects/${projectDetails?.id}/settings`,
          },
        ]
      : [
          {
            displayName: 'Projects',
            link: '/projects',
          },
          {
            displayName: projectDetails?.name ?? '',
            link: `/projects/${projectDetails?.id}/settings`,
          },
        ];

  const renderFeatureView = () => {
    if (featureId) {
      switch (featureSubtype) {
        case 'dashboard':
          return (
            <ChartDashboard
              project={project}
              feature={feature}
              id={featureId}
            />
          );

        case 'ranked list':
          return (
            <RankedListDisplay
              project={project}
              feature={feature}
              id={featureId}
              setLastUpdatedForRankedList={setLastUpdatedForRankedList}
            />
          );

        case 'table':
          return (
            <TableDisplay
              project={project}
              feature={feature}
              id={featureId}
              setLastUpdatedForTable={setLastUpdatedForRankedList}
            />
          );
        case 'map':
          return (
            <MapViewProvider
              project={project}
              feature={feature}
              id={featureId}
              setLastUpdatedForMap={setLastUpdatedForRankedList}
            >
              <MapView />
            </MapViewProvider>
          );
        case 'workflow':
          return (
            <WorkflowView
              project={project}
              featureName={feature?.data?.data?.name ?? ''}
              selectedRowTypeDataStore={selectedRowTypeDataStore}
            />
          );
      }
    }
  };

  const getTabbedNavigationElements = () => {
    switch (featureSubtype) {
      case 'workflow':
        return rowTypeDataStoresForWorkflowView?.map(rowTypeDataStore => {
          return (
            <TabbedNavigationBarElement key={rowTypeDataStore.uuid}>
              {rowTypeDataStore.title}
            </TabbedNavigationBarElement>
          );
        });
    }
  };

  const [dispatchExportEvent] = useLazyDispatchExportEventQuery();

  const downloadTableData = () => {
    //@ts-ignore
    if (typeof window.Tabulator !== 'undefined') {
      dispatchExportEvent(Number(featureId));
      //@ts-ignore
      window.Tabulator.download(
        'xlsx',
        `${projectDetails?.name} - ${featureDetails?.name}.xlsx`
      );
    }
  };
  const fullScreenForMap = (feature.data?.data as MapFeatureDetails)?.display
    ?.allowFullScreen;
  const fullScreenForDashboard = (feature.data?.data as DashboardFeatureDetails)
    ?.allowFullscreen;

  return (
    <AuthenticatedLayout
      fullscreen={featureType?.toLowerCase() === 'map'}
      breadcrumbElements={breadcrumbElements}
      logo={
        typeof projectDetails?.logo_url !== 'undefined'
          ? projectDetails.logo_url
          : process.env.REACT_APP_DEFAULT_PROJECT_LOGO
      }
      icon=""
      title={pageTitle}
      subTitle={pageSubTitle}
      actions={
        <div className="flex items-center gap-x-8">
          {featureType === 'Workflow' && (
            <p>
              <span className="font-semibold">Feature ID: </span>
              {featureId}
            </p>
          )}
          {(lastUpdated || lastUpdatedForRankedList) && (
            <p>
              <span className="font-semibold">Last Updated: </span>
              {formatDateAndTime(lastUpdated || lastUpdatedForRankedList)}
            </p>
          )}
          {!handle.active &&
            featureId !== 'new' &&
            (fullScreenForDashboard || fullScreenForMap) && (
              <Button icon={faExpand} onClick={enterFullscreen()}>
                Fullscreen
              </Button>
            )}
          {!handle.active &&
            featureId !== 'new' &&
            featureType?.toLowerCase() === 'table' && (
              <Button
                icon={faFileArrowDown}
                onClick={() => downloadTableData()}
              >
                Download Data
              </Button>
            )}
          {!handle.active &&
            featureId !== 'new' &&
            hasPermission(featurePermissions.general.settings_access) && (
              <Button
                icon={faGear}
                onClick={() => {
                  navigate(
                    `/projects/${projectId}/feature-groups/${featureGroupId}/${featureId}/${featureTypeForNavigation}`
                  );
                  setIsPublicView(false);
                }}
              >
                Setup
              </Button>
            )}
        </div>
      }
      sidebarData={
        projectDetails
          ? {
              type: SidebarTypes.PROJECT,
              data: projectDetails,
              backButtonCallback: () => navigate('/projects'),
            }
          : {
              type: SidebarTypes.PROJECT,
              data: undefined,
              backButtonCallback: () => navigate('/projects'),
            }
      }
      tabIndex={tabIndex}
      selectedTabIndexChanged={setTabIndex}
      tabNavigationElements={getTabbedNavigationElements()}
    >
      <>{renderFeatureView()}</>
    </AuthenticatedLayout>
  );
};

export default FeatureView;
