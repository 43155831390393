import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createErrorHandler } from '../../utils/createErrorHandler';
import ProjectResponseInterface, {
  AdministrationProjectResponseInterface,
  ProjectsByOrganisation,
  ProjectStatsResponseInterface,
} from '@/types/interfaces/api-response/ProjectResponseInterface';
import { IFavouriteResponse } from '@/layout/sidebar/Sidebar';

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: [
    'Projects',
    'Project',
    'ProjectTemplates',
    'AdministrationProjectStats',
    'AdministrationProjectList',
  ],
  endpoints: builder => ({
    getAllProjects: builder.query<ProjectsByOrganisation, number>({
      query: id => {
        return {
          url: '/projects',
          method: 'GET',
        };
      },
      providesTags: ['Projects'],
      keepUnusedDataFor: 1,
      onQueryStarted: createErrorHandler(
        'There was a problem loading projects'
      ),
    }),

    getProjectTemplates: builder.query<
      { data: ProjectResponseInterface[] },
      number
    >({
      query: id => {
        return {
          url: '/projects/available-templates',
          method: 'GET',
        };
      },
      providesTags: ['ProjectTemplates'],
      keepUnusedDataFor: 1,
      onQueryStarted: createErrorHandler(
        'There was a problem loading project Templates'
      ),
    }),

    getProjectSettings: builder.query<
      { data: ProjectResponseInterface },
      number
    >({
      query: (id: number) => {
        return {
          url: '/projects/' + id,
          method: 'GET',
        };
      },
      providesTags: ['Project'],
      keepUnusedDataFor: 1,
      onQueryStarted: createErrorHandler(
        'There was a problem loading project settings'
      ),
    }),

    storeProject: builder.mutation({
      query: (values: object) => {
        return {
          url: '/projects',
          method: 'POST',
          body: values,
          formData: true,
        };
      },
      invalidatesTags: [
        'Projects',
        'Project',
        'ProjectTemplates',
        'AdministrationProjectList',
        'AdministrationProjectStats',
      ],
    }),

    fetchProjectDetails: builder.query({
      query: (id: number) => {
        return {
          url: '/projects/' + id,
          method: 'GET',
        };
      },
      onQueryStarted: createErrorHandler(
        'There was a problem loading project details'
      ),
      keepUnusedDataFor: 1,
      providesTags: ['Project'],
    }),

    getProjectUsersForSelector: builder.query({
      query: (projectId: number) => {
        return {
          url: '/projects/' + projectId + '/users-for-selector',
          method: 'GET',
        };
      },
      onQueryStarted: createErrorHandler(
        'There was a problem loading project users'
      ),
    }),

    getAvailableProjectUsersForSelector: builder.query({
      query: (projectId: number) => {
        return {
          url: '/projects/' + projectId + '/available-users-for-selector',
          method: 'GET',
        };
      },
      onQueryStarted: createErrorHandler(
        'There was a problem loading project users'
      ),
    }),
    deleteProject: builder.mutation({
      query: (id: any) => {
        return {
          url: '/projects/' + id,
          method: 'DELETE',
        };
      },
      invalidatesTags: [
        'Projects',
        'ProjectTemplates',
        'AdministrationProjectList',
        'AdministrationProjectStats',
      ],
    }),

    toggleProject: builder.mutation({
      query: (projectId: string | number) => {
        return {
          url: '/projects/' + projectId + '/toggleTemplate',
          method: 'GET',
        };
      },
      invalidatesTags: [
        'Projects',
        'Project',
        'AdministrationProjectList',
        'ProjectTemplates',
        'AdministrationProjectStats',
      ],
    }),

    duplicateProject: builder.mutation({
      query: (data: object) => {
        return {
          url: '/projects/duplicate',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [
        'Projects',
        'AdministrationProjectList',
        'ProjectTemplates',
        'AdministrationProjectStats',
      ],
    }),

    archiveData: builder.mutation({
      query: (id: string) => {
        return {
          url: '/projects/' + id + '/archive-data',
          method: 'GET',
        };
      },
    }),

    favourite: builder.mutation<IFavouriteResponse, string>({
      query: (id: string) => {
        return {
          url: '/projects/' + id + '/favourites',
          method: 'PUT',
        };
      },
      invalidatesTags: [
        'Projects',
        'Project',
        'ProjectTemplates',
        'AdministrationProjectList',
        'AdministrationProjectStats',
      ],
    }),

    unFavourite: builder.mutation<IFavouriteResponse, string>({
      query: (id: string) => {
        return {
          url: '/projects/' + id + '/favourites',
          method: 'DELETE',
        };
      },
      invalidatesTags: [
        'Projects',
        'Project',
        'ProjectTemplates',
        'AdministrationProjectList',
        'AdministrationProjectStats',
      ],
    }),
    getProjectStats: builder.query<ProjectStatsResponseInterface, void>({
      query: () => {
        return {
          url: '/projects/stats',
          method: 'GET',
        };
      },
      providesTags: ['AdministrationProjectStats'],
    }),

    getAdministrationProjectList: builder.query<
      AdministrationProjectResponseInterface,
      void
    >({
      query: () => {
        return {
          url: '/projects/administration',
          method: 'GET',
        };
      },
      providesTags: ['AdministrationProjectList'],
    }),
    toggleProjectPublishStatus: builder.mutation({
      query: ({ id, is_published }: { id: number; is_published: boolean }) => {
        return {
          url: '/projects/' + id + '/publish',
          method: 'POST',
          body: { is_published },
        };
      },
      invalidatesTags: ['Projects', 'Project'],
    }),
  }),
});

export const {
  useGetAllProjectsQuery,
  useGetProjectTemplatesQuery,
  useGetProjectSettingsQuery,
  useStoreProjectMutation,
  useFetchProjectDetailsQuery,
  useGetProjectUsersForSelectorQuery,
  useGetAvailableProjectUsersForSelectorQuery,
  useDeleteProjectMutation,
  useToggleProjectMutation,
  useDuplicateProjectMutation,
  useArchiveDataMutation,
  useFavouriteMutation,
  useUnFavouriteMutation,
  useGetProjectStatsQuery,
  useGetAdministrationProjectListQuery,
  useToggleProjectPublishStatusMutation,
} = projectApi;
