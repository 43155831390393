import React, { useContext, createContext } from 'react';
import useMapViewProviderValues from './useMapViewProviderValues';
import { IMapConfigResponse, IMapDataResponse } from '../types/types';

type MapViewContextType = ReturnType<typeof useMapViewProviderValues>;

const MapViewContext = createContext<MapViewContextType | undefined>(undefined);

export interface MapViewProviderProps {
  children: React.ReactNode;
  id?: string | number;
  setLastUpdatedForMap?: React.Dispatch<React.SetStateAction<string>>;
  project?: any;
  feature?: any;
  isPublicView?: boolean;
  mapConfigFromProps?: IMapConfigResponse;
  mapDataFromProps?: IMapDataResponse;
  mapShapesResponseFromProps?: any;
  aggregatedDataFromProps?: any;
}

const MapViewProvider = (props: MapViewProviderProps) => {
  // benefits of using a custom hook to get the values of the context is that we don't need to write return types and default values for the context, as we can see on line no 5,type MapViewContextType = ReturnType<typeof useMapViewProviderValues>; we are getting the return type of the custom hook useMapViewProviderValues and assigning it to MapViewContextType, without this we would have to write the return type of the context and default values for the context explicitly.
  const contextValues = useMapViewProviderValues(props);
  return (
    <MapViewContext.Provider value={contextValues}>
      {props.children}
    </MapViewContext.Provider>
  );
};

export const useMapViewContext = () => {
  const context = useContext(MapViewContext);
  if (!context) {
    throw new Error('useMapViewContext must be used within a MapViewProvider');
  }
  return context;
};

export default MapViewProvider;
