import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { SurveyResponse } from '@/pages/survey/Helpers/Types';

export const generalApi = createApi({
  reducerPath: 'generalApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  endpoints: builder => ({
    dispatchExportEvent: builder.query<SurveyResponse, number>({
      query: (featureId: number) => {
        return {
          url: `/features/${featureId}/dispatch-export`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0.01,
    }),

    validateToken: builder.query<
      { success: boolean },
      { token: string; email: string }
    >({
      query: ({ token, email }) => {
        return {
          url: `/auth/validate-token/${token}/${email}`,
          method: 'GET',
        };
      },
    }),
    sendFeedback: builder.mutation<
      any,
      { message: string; page: string; width: number; height: number }
    >({
      query: ({ message, page, width, height }) => {
        return {
          url: '/feedback',
          method: 'POST',
          body: { message, page, width, height },
        };
      },
    }),
    uploadImage: builder.mutation({
      query: (data: FormData) => {
        return {
          url: '/image_upload',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useLazyDispatchExportEventQuery,
  useValidateTokenQuery,
  useSendFeedbackMutation,
  useUploadImageMutation,
} = generalApi;
