import axios from 'axios';
import { INearestPostcodeResponse } from '../types/types';

// // TODO: Move this to RTK Query
export const fetchNearestPostcodes = async (postcodes: string[]) => {
  const invalidPostcodes = new Set(['unknown', 'null', 'undefined']);

  const filteredPostcodes = postcodes.filter(
    postcode =>
      !invalidPostcodes.has(postcode.toLowerCase()) &&
      Number.isNaN(Number(postcode))
  );
  try {
    const response = await axios.post<string[], INearestPostcodeResponse>(
      `${process.env.REACT_APP_API_BASE_URL}/geolocate/`,
      {
        postcodes: filteredPostcodes,
      }
    );
    const allPostcodesWithCoords = response.data.reduce(
      (acc: Record<string, [number, number]>, curr) => {
        const latLngString = curr.result.latlng;
        if (
          latLngString &&
          latLngString.trim() !== '' &&
          latLngString !== 'Unknown'
        ) {
          const latLngArray = latLngString.split(' ');

          if (latLngArray.length === 2) {
            const lat = parseFloat(latLngArray[0]);
            const lng = parseFloat(latLngArray[1]);

            if (!isNaN(lat) && !isNaN(lng)) {
              acc[curr.location] = [lat, lng];
            }
          }
        }

        return acc;
      },
      {}
    );
    return allPostcodesWithCoords;
  } catch (error) {
    return null;
  }
};

export const validateUKPostcode = (postcode: string) => {
  const postcodeRegex =
    /\b(?:(?:[A-Z][A-HJ-Y]?[0-9][0-9A-Z]?|ASCN|STHL|TDCU|BBND|[BFS]IQ{2}|GX11|PCRN|TKCA) ?[0-9][A-Z]{2}|GIR ?0A{2}|SAN ?TA1|AI-?[0-9]{4}|BFPO[ -]?[0-9]{2,3}|MSR[ -]?1(?:1[12]|[23][135])0|VG[ -]?11[1-6]0|[A-Z]{2} ? [0-9]{2}|KY[1-3][ -]?[0-2][0-9]{3})\b/;
  return postcodeRegex.test(postcode);
};
