import React, { ReactElement } from 'react';
import { useCustomGraphContext } from '../../CustomGraphProvider';
import Field from '@/pages/dashboard/projects/graph/nodes/form-fields/Field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@/tcomponents/ui/button';
import Mappable from '@/pages/dashboard/projects/graph/types/mappable';

const FieldMapFormFields = (): ReactElement => {
  const { updateNodeData, activeNode } = useCustomGraphContext();

  const addField = (): void => {
    const data = {
      ...activeNode.data,
      fields: [...activeNode.data.fields],
    };

    data.fields.push({
      input: '',
      output: '',
    });

    updateNodeData(data);
  };

  const updateField = (index: number, field: Mappable): void => {
    const data = {
      ...activeNode.data,
      fields: [...activeNode.data.fields],
    };

    data.fields[index] = field;

    updateNodeData(data);
  };

  const deleteField = (index: number): void => {
    const data = {
      ...activeNode.data,
      fields: [...activeNode.data.fields],
    };

    data.fields = data.fields.filter(
      (_: Mappable, i: number): boolean => i !== index
    );

    updateNodeData(data);
  };

  return (
    <div className="flex flex-col gap-2 border p-4 shadow-md m-2 bg-white rounded-md">
      <div className="grid grid-cols-[repeat(2,_minmax(0,_1fr))_40px] gap-x-4 gap-y-2 border-b pb-2">
        <h5 className="col-span-3 border-b pb-2">Fields</h5>

        <div className="text-xs font-bold">Old Field</div>

        <div className="text-xs font-bold">New Field</div>
      </div>

      {activeNode.data.fields.map((field: Mappable, index: number) => (
        <Field
          key={index}
          field={field}
          onChange={v => updateField(index, v)}
          onDelete={() => deleteField(index)}
        />
      ))}

      <Button
        variant="info"
        className="text-white flex items-center justify-center space-x-2"
        onClick={addField}
      >
        <FontAwesomeIcon icon={faPlus} />

        <span>New Field</span>
      </Button>
    </div>
  );
};

export default FieldMapFormFields;
