import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthenticatedLayout from '../../../../layout/AuthenticatedLayout';
import SidebarTypes from '../../../../types/enums/common/SidebarTypes';
import Grid from '../../../../components/common/grid/Grid';
import Numbers from '../../../../types/enums/common/Numbers';
import GridModifiers from '../../../../types/enums/grid/GridModifiers';
import Column from '../../../../components/common/grid/Column';
import ProjectResponseInterface from '../../../../types/interfaces/api-response/ProjectResponseInterface';
import LinkInterface from '../../../../types/interfaces/LinkInterface';
import { NavigationContext } from '../../../../context/NavigationContext';
import { useFetchProjectDetailsQuery } from '../../../../store/services/projects/projectApi';
import {
  useGetFeatureGroupQuery,
  useCreateOrUpdateFeatureGroupMutation,
  useDeleteFeatureGroupMutation,
} from '../../../../store/services/projects/featureGroupApi';
import TabbedNavigationBarElement from '../../../../layout/tab-navigation/TabbedNavigationBarElement';
import { Button } from '@tcomponents/ui/button';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Input } from '@/tcomponents/ui/input';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import ModalTypes from '../../../../types/enums/modal/ModalTypes';
import { ModalContext } from '../../../../context/ModalContext';
import FeatureGroupFeatures from './FeatureGroupFeatures';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/tcomponents/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/tcomponents/ui/form';
import { Textarea } from '@/tcomponents/ui/textarea';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { FeatureDetails } from '@/types/feature/featureDetails';
import { isApiErrorResponse } from '@/utils/isApiErrorResponse';

interface IFeatureGroupProps {}

interface IFeatureGroupResponse {
  id?: number;
  name: string;
  description?: string;
  icon?: string;
  features?: Array<FeatureDetails>;
}

const formSchema = object({
  name: string().min(1, 'Name is required'),
  description: string().optional(),
});

export type FormInput = TypeOf<typeof formSchema>;

const FeatureGroup: FC<IFeatureGroupProps> = () => {
  const { setActivePage } = useContext(NavigationContext);
  const { projectId, featureGroupId } = useParams();
  const [pageTitle, setPageTitle] = useState(
    featureGroupId ? 'Loading...' : 'New Feature Group'
  );
  const [projectDetails, setProjectDetails] =
    useState<ProjectResponseInterface>();
  const navigate = useNavigate();
  const refSubmitButton = useRef<HTMLButtonElement>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const { showDialog, hideDialog } = useContext(ModalContext);
  const [isNew, setIsNew] = useState(false);
  const [featureGroupData, setFeatureGroupData] =
    useState<IFeatureGroupResponse>({
      name: '',
      description: '',
    });

  const form = useForm<FormInput>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
    },
  });
  const {
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = form;

  const breadcrumbElements: Array<LinkInterface> = [
    {
      displayName: 'Projects',
      link: '/projects',
    },
    {
      displayName: projectDetails?.name ? projectDetails.name : 'Loading...',
      link: '/projects/' + projectId + '/settings',
    },
  ];

  const { data, isSuccess, isError, error } = useFetchProjectDetailsQuery(
    Number(projectId)
  );
  const {
    data: getFeatureGroupData,
    isError: getFeatureGroupIsError,
    isSuccess: getFeatureGroupIsSuccess,
    error: featureGroupError,
    refetch,
  } = useGetFeatureGroupQuery(
    {
      id: Number(featureGroupId),
      projectId: Number(projectId),
    },
    {
      skip: !featureGroupId || !projectId,
    }
  );
  const [
    createOrUpdateFeatureGroup,
    { data: storingData, isSuccess: isStoringSuccess, isError: isStoringError },
  ] = useCreateOrUpdateFeatureGroupMutation();

  useEffect(() => {
    setActivePage('My Projects');
    if (isSuccess) {
      setProjectDetails(data.data);
    }
    if (isError) {
      if (isApiErrorResponse(error) && error.status !== 403) {
        toast({
          title: 'Uh oh! Something went wrong',
          variant: 'destructive',
          description: error.data.message,
          duration: 24 * 60 * 60000,
        });

        navigate('/not-found');
      } else {
        navigate('/unauthorized');
      }
    }
  }, [data, isError, isSuccess, setActivePage, error]);

  const getTabbedNavigationElements = () => {
    let elements = [
      <TabbedNavigationBarElement>Details</TabbedNavigationBarElement>,
      <TabbedNavigationBarElement disabled={!featureGroupId}>
        Features
      </TabbedNavigationBarElement>,
    ];

    return elements;
  };

  const canSave = () => {
    return isDirty;
  };

  useEffect(() => {
    if (getFeatureGroupData && featureGroupId && getFeatureGroupIsSuccess) {
      setPageTitle(getFeatureGroupData.data?.name ?? '');
      let obj = {
        ...getFeatureGroupData.data,
        ...{ description: getFeatureGroupData.data.description ?? '' },
      };
      reset(obj);
      setFeatureGroupData(obj);
    }
    if (featureGroupId && getFeatureGroupIsError) {
      if (
        isApiErrorResponse(featureGroupError) &&
        featureGroupError.status !== 403
      ) {
        toast({
          title: 'Uh oh! Something went wrong',
          variant: 'destructive',
          description: featureGroupError.data.message,
          duration: 24 * 60 * 60000,
        });

        navigate('/not-found');
      } else {
        navigate('/unauthorized');
      }
    }
  }, [
    data,
    getFeatureGroupIsError,
    getFeatureGroupIsSuccess,
    featureGroupId,
    getFeatureGroupData,
    featureGroupError,
  ]);

  useEffect(() => {
    if (!featureGroupId && isStoringSuccess) {
      navigate(
        '/projects/' + projectId + '/feature-groups/' + storingData.data.id
      );
      toast({
        description: 'Feature Group created',
        duration: 3000,
      });
      refetch();
    }
    if (!featureGroupId && isStoringError) {
      showDialog({
        title: 'Error while creating the feature group.',
        type: ModalTypes.DANGER,
        message: 'Something went wrong.',
        cancel: true,
      });
    }

    if (featureGroupId && !isNew && isStoringSuccess) {
      toast({
        description: 'Your changes have been saved',
        duration: 3000,
      });
      refetch();
    }
    if (featureGroupId && isStoringError) {
      showDialog({
        title: 'Error while updating the feature group.',
        type: ModalTypes.DANGER,
        message: 'Something went wrong.',
        cancel: true,
      });
    }
    hideDialog();
  }, [isStoringSuccess, isStoringError, featureGroupId, storingData]);

  const onSubmitHandler: SubmitHandler<FormInput> = values => {
    featureGroupId ? update(featureGroupId, values) : create(values);
  };

  const create = (values: typeof featureGroupData) => {
    setIsNew(true);
    createOrUpdateFeatureGroup({ project_id: projectId, ...values });
  };

  const update = (id: number | string, values: typeof featureGroupData) => {
    createOrUpdateFeatureGroup({ project_id: projectId, id, ...values });
  };

  const renderFeatureGroupForm = () => {
    return (
      <div className="grid grid-cols-1">
        <Card>
          <CardHeader>
            <CardTitle>Feature Group Details</CardTitle>
          </CardHeader>
          <CardContent>
            <Form {...form}>
              <form
                className="space-y-4"
                onSubmit={handleSubmit(onSubmitHandler)}
              >
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex items-center justify-between">
                        <FormLabel required>Name</FormLabel>
                        <FormMessage />
                      </div>
                      <FormControl>
                        <Input {...field} error={errors.name} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex justify-between">
                        <FormLabel>Description</FormLabel>
                        <FormMessage />
                      </div>
                      <FormControl>
                        <Textarea {...field} error={errors.description} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <button hidden={true} ref={refSubmitButton} type={'submit'} />
              </form>
            </Form>
          </CardContent>
        </Card>
      </div>
    );
  };

  const renderFeatureGroupFeatures = () => {
    if (tabIndex === 1 && featureGroupId) {
      return (
        <FeatureGroupFeatures id={featureGroupId} project_id={projectId} />
      );
    }
    return null;
  };

  const canDelete = () => {
    return (
      typeof featureGroupData.features === 'undefined' ||
      featureGroupData.features.length === 0
    );
  };

  const showDeleteModal = () => {
    showDialog({
      title: 'Confirm feature group deletion',
      message: `Are you sure you want to delete feature group ${featureGroupData.name}?`,
      type: ModalTypes.WARNING,
      cancel: true,
      ok: {
        cb: () => callDeleteFeatureGroupRequest(featureGroupData?.id || 0),
      },
    });
  };

  const [
    deleteFeatureGroup,
    { isSuccess: isDeleteSuccess, isError: isDeleteError, error: deleteError },
  ] = useDeleteFeatureGroupMutation();

  const callDeleteFeatureGroupRequest = async (id: string | number) => {
    await deleteFeatureGroup({ id: Number(id), projectId: Number(projectId) });
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      navigate(`/projects/${projectId}/settings`);
      toast({
        description: 'Feature Group deleted',
        duration: 3000,
      });
    }
    if (isApiErrorResponse(deleteError) && isDeleteError) {
      hideDialog();
      showDialog({
        title: 'Error while deleting the feature group.',
        type: ModalTypes.DANGER,
        message: deleteError.data.message,
        cancel: true,
      });
    }
  }, [isDeleteSuccess, isDeleteError, deleteError]);

  return (
    <AuthenticatedLayout
      logo={
        typeof projectDetails?.logo_url !== 'undefined'
          ? projectDetails.logo_url
          : process.env.REACT_APP_DEFAULT_PROJECT_LOGO
      }
      icon=""
      title={pageTitle}
      breadcrumbElements={breadcrumbElements}
      actions={[
        <div className="flex items-center gap-x-3">
          {featureGroupId && (
            <Button
              disabled={!canDelete()}
              variant={'danger'}
              className="p-4"
              icon={faTrashAlt}
              tooltip={
                !canDelete()
                  ? 'You cannot delete a feature group if it already has features'
                  : ''
              }
              onClick={() => showDeleteModal()}
              data-testing="feature-group-delete-button"
            >
              Delete
            </Button>
          )}
          <Button
            disabled={!canSave()}
            icon={faFloppyDisk}
            className="p-4"
            onClick={() => {
              refSubmitButton?.current?.click();
            }}
            data-testing={
              featureGroupId
                ? 'feature-group-save-button'
                : 'feature-group-create-button'
            }
          >
            {featureGroupId ? 'Save' : 'Create'}
          </Button>
        </div>,
      ]}
      tabIndex={tabIndex}
      selectedTabIndexChanged={setTabIndex}
      tabNavigationElements={getTabbedNavigationElements()}
      sidebarData={
        projectDetails
          ? {
              type: SidebarTypes.PROJECT,
              data: projectDetails,
              backButtonCallback: () => navigate('/projects'),
            }
          : {
              type: SidebarTypes.PROJECT,
              data: undefined,
              backButtonCallback: () => navigate('/projects'),
            }
      }
    >
      <Grid size={Numbers.ONE} modifiers={[GridModifiers.STACKABLE]}>
        <Column>
          {projectDetails ? (
            <>
              {tabIndex === 0 && renderFeatureGroupForm()}
              {renderFeatureGroupFeatures()}
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Column>
      </Grid>
    </AuthenticatedLayout>
  );
};

export default FeatureGroup;
