import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
} from 'react';
import {
  DiffSourceToggleWrapper,
  InsertTable,
  InsertThematicBreak,
  ListsToggle,
  MDXEditor,
  Separator,
  diffSourcePlugin,
  linkPlugin,
  listsPlugin,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  MDXEditorMethods,
} from '@mdxeditor/editor';
import { headingsPlugin } from '@mdxeditor/editor';
import { linkDialogPlugin } from '@mdxeditor/editor';
import { UndoRedo } from '@mdxeditor/editor';
import { BoldItalicUnderlineToggles } from '@mdxeditor/editor';
import { toolbarPlugin } from '@mdxeditor/editor';
import { BlockTypeSelect } from '@mdxeditor/editor';
import { CreateLink } from '@mdxeditor/editor';
import {
  useCreateOrUpdateFeatureInformationMutation,
  useGetFeatureInformationQuery,
} from '@/store/services/projects/featureApi';
import { useSaveActionContext } from '@/context/SaveActionContext';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { SaveRefHandle } from '@/hooks/useSaveTabs';

type InformationTabProps = {
  id: string | number;
};

const InformationTab = React.forwardRef<SaveRefHandle, InformationTabProps>(
  (props: InformationTabProps, ref) => {
    const { id } = props;

    const markdownEditorRef = useRef<MDXEditorMethods>(null);
    const initialRenderRef = useRef(true);
    const { setIsDirty, setSaveAction } = useSaveActionContext();
    const [markdown, setMarkdown] = useState('');

    const { data } = useGetFeatureInformationQuery(Number(id), {
      skip: !id || id === 'new',
    });
    const markdownData = data?.data?.information_text;

    const onChange = useCallback(
      (value: string) => {
        setMarkdown(value);
        if (!initialRenderRef.current) {
          setIsDirty(true);
        }
        initialRenderRef.current = false;
      },
      [setIsDirty]
    );

    const [
      createOrUpdateFeatureInformation,
      { isSuccess: isCreateOrUpdateSuccess, isError: isCreateOrUpdateError },
    ] = useCreateOrUpdateFeatureInformationMutation();

    const saveInformation = useCallback(async () => {
      await createOrUpdateFeatureInformation({
        id,
        markdown,
      });
      setIsDirty(false);
    }, [createOrUpdateFeatureInformation, id, markdown, setIsDirty]);

    useImperativeHandle(
      ref,
      () => {
        return {
          save: saveInformation,
        };
      },
      [saveInformation]
    );

    useEffect(() => {
      if (isCreateOrUpdateSuccess) {
        toast({
          description: 'Your changes have been saved',
          duration: 3000,
        });
      }
      if (isCreateOrUpdateError) {
        toast({
          variant: 'destructive',
          description: 'Something went wrong',
          duration: 3000,
        });
      }
    }, [isCreateOrUpdateSuccess, isCreateOrUpdateError]);

    useEffect(() => {
      if (markdownData) {
        markdownEditorRef.current?.setMarkdown(markdownData);
      }
    }, [markdownData]);

    // useEffect to apply background color class on the toolbar which has role="toolbar"
    useEffect(() => {
      const toolbar = document.querySelector('[role="toolbar"]');
      if (toolbar) {
        toolbar.classList.add('bg-[#D3D3D3]');
        toolbar.classList.add('rounded-none');
      }
    }, []);

    useEffect(() => {
      setSaveAction({
        callback: () => {
          saveInformation();
        },
      });
    }, [saveInformation, setSaveAction]);

    return (
      <div className="flex flex-col w-full p-4 bg-white border rounded-md shadow-sm h-[calc(100vh-140px)] gap-y-4">
        <h4>Information details</h4>
        <MDXEditor
          placeholder="Enter optional descriptive details here"
          ref={markdownEditorRef}
          markdown={markdown}
          onChange={onChange}
          plugins={[
            headingsPlugin(),
            quotePlugin(),
            linkPlugin(),
            linkDialogPlugin(),
            tablePlugin(),
            listsPlugin(),
            thematicBreakPlugin(),
            diffSourcePlugin(),
            toolbarPlugin({
              toolbarContents: () => (
                <DiffSourceToggleWrapper>
                  <UndoRedo />
                  <Separator />
                  <BoldItalicUnderlineToggles />
                  <Separator />
                  <BlockTypeSelect />
                  <Separator />
                  <CreateLink />
                  <Separator />
                  <InsertTable />
                  <InsertThematicBreak />
                  <Separator />
                  <ListsToggle />
                </DiffSourceToggleWrapper>
              ),
            }),
          ]}
        />
      </div>
    );
  }
);

export default InformationTab;
