import { useEffect, useRef } from 'react';
import {
  DiffSourceToggleWrapper,
  InsertTable,
  InsertThematicBreak,
  ListsToggle,
  MDXEditor,
  Separator,
  diffSourcePlugin,
  linkPlugin,
  listsPlugin,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  MDXEditorMethods,
  MDXEditorProps,
} from '@mdxeditor/editor';
import { headingsPlugin } from '@mdxeditor/editor';
import { linkDialogPlugin } from '@mdxeditor/editor';
import { UndoRedo } from '@mdxeditor/editor';
import { BoldItalicUnderlineToggles } from '@mdxeditor/editor';
import { toolbarPlugin } from '@mdxeditor/editor';
import { BlockTypeSelect } from '@mdxeditor/editor';
import { CreateLink } from '@mdxeditor/editor';

type ToolbarItem = 'insertTable';

interface CustomMarkdownEditorProps extends MDXEditorProps {
  markdown: string;
  onChange: (value: string) => void;
  showDiffSourceToggle?: boolean;
  excludedToolbarItems?: ToolbarItem[];
}

const CustomMarkdownEditor: React.FC<CustomMarkdownEditorProps> = ({
  markdown,
  onChange,
  showDiffSourceToggle = true,
  excludedToolbarItems = [],
  ...props
}) => {
  const markdownEditorRef = useRef<MDXEditorMethods>(null);
  const isExcluded = (item: ToolbarItem) => excludedToolbarItems.includes(item);

  // useEffect to apply background color class on the toolbar which has role="toolbar"
  useEffect(() => {
    const toolbar = document.querySelector('[role="toolbar"]');
    if (toolbar) {
      toolbar.classList.add('bg-[#D3D3D3]');
      toolbar.classList.add('rounded-none');
    }
  }, []);

  return (
    <MDXEditor
      ref={markdownEditorRef}
      markdown={markdown}
      onChange={onChange}
      plugins={[
        headingsPlugin(),
        quotePlugin(),
        linkPlugin(),
        linkDialogPlugin(),
        tablePlugin(),
        listsPlugin(),
        thematicBreakPlugin(),
        ...(showDiffSourceToggle ? [diffSourcePlugin()] : []),
        toolbarPlugin({
          toolbarContents: () =>
            showDiffSourceToggle ? (
              <DiffSourceToggleWrapper>
                <UndoRedo />
                <Separator />
                <BoldItalicUnderlineToggles />
                <Separator />
                <BlockTypeSelect />
                <Separator />
                <CreateLink />
                <Separator />
                {!isExcluded('insertTable') && <InsertTable />}
                <InsertThematicBreak />
                <Separator />
                <ListsToggle />
              </DiffSourceToggleWrapper>
            ) : (
              <>
                <UndoRedo />
                <Separator />
                <BoldItalicUnderlineToggles />
                <Separator />
                <BlockTypeSelect />
                <Separator />
                <CreateLink />
                <Separator />
                {!isExcluded('insertTable') && <InsertTable />}
                <InsertThematicBreak />
                <Separator />
                <ListsToggle />
              </>
            ),
        }),
      ]}
      {...props}
    />
  );
};

export default CustomMarkdownEditor;
