import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { MapFeatureDetails } from '@/types/feature/featureDetails';
import { OverlayData } from './OverlayRender';
import { BoundaryData } from './BoundaryRenderer';
import { useActiveLayerInteractions } from '../hooks/useActiveLayerInteractions';
import { useMapViewContext } from '../mapContext/MapViewProvider';

type BoundaryType = MapFeatureDetails['boundaries'][0];

type Props = {
  data: OverlayData | undefined | BoundaryData;
  fileType: 'Shape' | 'GeoJSON';
  layer: MapFeatureDetails['overlays'][0] | BoundaryType;
  layerType?: 'overlay' | 'boundary';
};
export const ShapeFileWithDataProp = (props: Props) => {
  const { data, fileType, layer, layerType } = props;
  const { setActiveBoundaryFeature, activeBoundaryFeature } =
    useMapViewContext();
  const map = useMap();

  const isBoundaryLayer = layerType === 'boundary';
  const boundaryOpacity = (layer as BoundaryType).opacity ?? 0.5;

  const {
    handleLayerHighlightOnMouseOver,
    handleLayerResetOnMouseOut,
    handleActiveLayerStyling,
    resetLayerStylesOnDeselection,
  } = useActiveLayerInteractions(boundaryOpacity);

  useEffect(() => {
    if (!data || !map) return;
    const geo = L.geoJson([], {
      onEachFeature: function (f, l) {
        if (isBoundaryLayer) {
          l.on({
            click: () => setActiveBoundaryFeature(f),
            mouseover: handleLayerHighlightOnMouseOver,
            mouseout: handleLayerResetOnMouseOut,
          });
        }

        if (f.properties) {
          // Get the hoverProperties from the overlay and convert to lower case, we had to do this because in overlay properties are in different case
          const hoverProperties = layer.hoverProperties
            .filter(prop => prop.label !== null && prop.value !== null)
            .map(prop => prop.value.toLowerCase());
          // Filter the properties based on hoverProperties
          if (hoverProperties.length > 0) {
            // Create a new object with lower case keys
            const lowerCaseProperties = Object.fromEntries(
              Object.entries(f.properties).map(([key, value]) => [
                key.toLowerCase(),
                value,
              ])
            );
            const out = hoverProperties
              ?.filter(
                (key: string) =>
                  key in lowerCaseProperties && lowerCaseProperties[key]
              )
              ?.map(
                (key: string, index: number) =>
                  `<strong key=${index}>${
                    key.charAt(0).toUpperCase() + key.slice(1)
                  }</strong> <br />
                 ${lowerCaseProperties[key]} <br />`
              );
            if (out.length) {
              l.bindPopup(out.join('<br />'));
            }
          }
        }
      },
      style: function () {
        if (isBoundaryLayer) {
          return {
            fillColor: layer.color ?? '#3388ff',
            color: '#ccc',
            weight: 1,
            fillOpacity: boundaryOpacity,
          };
        } else {
          return {
            color: layer.color ?? '#3388ff',
            weight: 1,
            fillOpacity: 0.1,
          };
        }
      },
    }).addTo(map);

    const loadFile = async () => {
      try {
        geo.addData(data);
        if (isBoundaryLayer) {
          geo.bringToBack();
        }
      } catch (err) {
        console.error(err);
        toast({
          title: 'Oh no!',
          variant: 'destructive',
          description: `Failed to load ${fileType} file: ${layer.title}`,
        });
      }
    };

    loadFile();

    // this logic is to handle the selection/deselection of active layer on the map
    if (activeBoundaryFeature && isBoundaryLayer) {
      handleActiveLayerStyling(geo);
      resetLayerStylesOnDeselection(geo);
    }

    return () => {
      map.removeLayer(geo);
    };
  }, [data, fileType, map, layer, activeBoundaryFeature]);

  return null;
};
