import { useCallback, useEffect, useState } from 'react';
import {
  IMapConfigResponse,
  IMapData,
  IMapDataResponse,
  IQuestionAndAnswerVisibility,
  TMarker,
} from '../types/types';
import { config } from '../config';
import { MapFeatureDetails } from '@/types/feature/featureDetails';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { objectKeysToLowerCase } from '../helpers/caseConversion';

export const useCreateMarkers = (
  mapConfigResponse: IMapConfigResponse | undefined,
  mapDataResponse: IMapDataResponse | undefined,
  includeValueField: boolean | undefined,
  selectedQuestionsMeta: MapFeatureDetails['selectedQuestionsMeta'] | undefined,
  questionsAndAnswersVisibility: IQuestionAndAnswerVisibility,
  visibleQuestion: string | undefined,
  postcodesWithCoords: Record<string, [number, number]> | null,
  activeBoundaryFeature: Feature<Geometry, GeoJsonProperties> | undefined,
  visibleBoundaryConfigData: MapFeatureDetails['boundaries'][0] | undefined,
  id: string | number | undefined
) => {
  const [markersData, setMarkersData] = useState<TMarker[]>([]);
  const [filteredMarkersData, setFilteredMarkersData] = useState<TMarker[]>([]);

  function getMarkerVisibility(markerData: TMarker, visibleQuestion?: string) {
    if (!visibleQuestion) return true;
    if (visibleQuestion && !(visibleQuestion in markerData)) return false;

    const markerAnswerArray = Array.isArray(markerData[visibleQuestion])
      ? markerData[visibleQuestion]
      : [markerData[visibleQuestion]];

    // Hiding markers that have no answer
    if (markerAnswerArray.length === 0) {
      return false;
    }
    const answerString = markerAnswerArray[0];
    const answerVisibility =
      questionsAndAnswersVisibility[visibleQuestion].answers[answerString];

    return answerVisibility;
  }

  function getMarkerColor(markerData: TMarker, visibleQuestion?: string) {
    if (!visibleQuestion) return config.defaultMarkerColor;

    const questionMeta = selectedQuestionsMeta?.find(
      item => item.question === visibleQuestion
    );

    const markerAnswer = Array.isArray(markerData[visibleQuestion])
      ? markerData[visibleQuestion]
      : [markerData[visibleQuestion]];

    const markerColor = markerAnswer.map(
      (answer: string) =>
        questionMeta?.answers?.find(answerMeta => answerMeta.answer === answer)
          ?.color || config.defaultMarkerColor
    );
    return markerColor[0];
  }

  const createMarker = (
    item: IMapData,
    idx: number,
    lat: number,
    lng: number,
    visibleQuestion?: string
  ) => {
    const marker = {
      ...item,
      lat,
      lng,
      quetsionTitle: visibleQuestion,
      markerAnswer: item[visibleQuestion as string],
      includeValueField,
      visible: true, // check if question-answer combo is visible
      color: config.defaultMarkerColor, // use color from question-answer combo in map config styling
      displayedFields: Object.fromEntries(
        mapConfigResponse!.data.detailView.displayFieldsOfInterest.map(
          (
            item: MapFeatureDetails['detailView']['displayFieldsOfInterest'][0]
          ) => [item.label, mapDataResponse!.data[idx][item.label]]
        )
      ),
    };
    marker.visible = getMarkerVisibility(marker, visibleQuestion);
    marker.color = getMarkerColor(marker, visibleQuestion);
    return marker;
  };

  const fetchData = useCallback(
    async (visibleQuestion?: string) => {
      try {
        const locationFormat = mapConfigResponse?.data.layout.format;
        if (locationFormat === 'postcode') {
          const locationField = mapConfigResponse?.data.layout
            .locationField as string;

          if (!postcodesWithCoords) return [];
          const markerData = mapDataResponse!.data.reduce(
            (acc: TMarker[], item: IMapData, idx: number) => {
              const isMarkerAnswerValid = !!item[visibleQuestion as string];
              if (
                item[locationField] in postcodesWithCoords &&
                isMarkerAnswerValid
              ) {
                const [lat, lng] = postcodesWithCoords[item[locationField]];
                const marker = createMarker(
                  item,
                  idx,
                  lat,
                  lng,
                  visibleQuestion
                );
                acc.push(marker);
              }
              return acc;
            },
            []
          );
          return markerData;
        } else if (locationFormat === 'lat/long') {
          const markerData = mapDataResponse!.data.reduce(
            (acc: TMarker[], item: IMapData, idx: number) => {
              const locationData =
                item[mapConfigResponse!.data.layout.locationField];
              const isMarkerAnswerValid = !!item[visibleQuestion as string];
              if (
                Array.isArray(locationData) &&
                locationData.length >= 2 &&
                isMarkerAnswerValid
              ) {
                const [lat, lng] = locationData;
                const marker = createMarker(
                  item,
                  idx,
                  lat,
                  lng,
                  visibleQuestion
                );
                acc.push(marker);
              }
              return acc;
            },
            []
          );
          return markerData;
        } else {
          return [];
        }
      } catch (error) {
        return [];
      }
    },
    [visibleQuestion, mapDataResponse, mapConfigResponse, postcodesWithCoords]
  );

  // useEffect to reset the states to default when the id of the map changes
  useEffect(() => {
    setMarkersData([]);
    setFilteredMarkersData([]);
  }, [id]);

  // useEffect to fetch lat long data and update markersData
  useEffect(() => {
    if (mapDataResponse?.data && mapConfigResponse?.data) {
      fetchData(visibleQuestion).then(setMarkersData);
    }
  }, [
    mapDataResponse?.data,
    mapConfigResponse?.data,
    visibleQuestion,
    fetchData,
  ]);

  // useEffect to update markersData based on questionsAndAnswersVisibility
  useEffect(() => {
    const updatedMarkersData1 =
      markersData &&
      markersData.map(markerData => {
        return {
          ...markerData,
          color: getMarkerColor(markerData, visibleQuestion),
          visible: getMarkerVisibility(markerData, visibleQuestion),
          quetsionTitle: visibleQuestion,
          markerAnswer: markerData[visibleQuestion as string],
        };
      });
    setMarkersData(updatedMarkersData1);
  }, [questionsAndAnswersVisibility]);

  // useEffect to update markersData based on activeBoundaryFeature
  useEffect(() => {
    const visibleBoundaryDataStoreMarkerfield =
      visibleBoundaryConfigData?.dataStoreMarkerField;

    if (!visibleBoundaryDataStoreMarkerfield) {
      setFilteredMarkersData(markersData);
      return;
    }
    if (visibleBoundaryDataStoreMarkerfield && !activeBoundaryFeature) {
      setFilteredMarkersData([]);
      return;
    }

    if (
      visibleBoundaryDataStoreMarkerfield &&
      activeBoundaryFeature &&
      markersData.length > 0
    ) {
      const visibleBoundaryKeyPropertyLowerCase =
        visibleBoundaryConfigData?.keyProperty?.toLowerCase();

      const activeFeatureProperties = activeBoundaryFeature?.properties;
      const activeFeaturePropertiesWithLowerCaseKeys = objectKeysToLowerCase(
        activeFeatureProperties
      );

      const filterdMarkersDataBasedOnActiveBoundary = markersData.filter(
        markerData =>
          markerData[visibleBoundaryDataStoreMarkerfield] ===
          activeFeaturePropertiesWithLowerCaseKeys[
            visibleBoundaryKeyPropertyLowerCase
          ]
      );

      setFilteredMarkersData(filterdMarkersDataBasedOnActiveBoundary);
    }
  }, [activeBoundaryFeature, visibleBoundaryConfigData, markersData]);

  return {
    markersData: filteredMarkersData,
  };
};
