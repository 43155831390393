import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/tcomponents/ui/card';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/tcomponents/ui/select';
import { Label } from '@/tcomponents/ui/label';
import { Switch } from '@/tcomponents/ui/switch';
import ReactSelect from 'react-select';
import { defaultDataStyling } from '@/pages/dashboard/features/forms/RankedList/consts';
import { SaveActionContext } from '@/context/SaveActionContext';
import { useMapSettingsContext } from '../MapSettingsProvider';
import { SketchPicker } from 'react-color';
import { MapFormStateType } from '../helpers/formStates';
import { prepareQuestionsMeta } from '../utils/markersTabFunctions';

const EmptyDataStore = () => {
  return (
    <h3 className="flex flex-col items-center justify-center h-full text-gray-400 gap-y-1">
      Empty Data Store
    </h3>
  );
};

export const MarkersTab = () => {
  const [activeAnswerColorPickerIdx, setActiveAnswerColorPickerIdx] =
    useState<[number, number]>();
  const { setIsDirty } = useContext(SaveActionContext);
  const {
    formContent,
    setFormContent,
    workflowData,
    finalDataStoreDataForMarkersTab: finalData,
    selectedWorkflowInMarkersTab: selectedWorkflow,
    dataStoresOptionsMarkersTab: dataStores,
    selectedDataStoreInMarkersTab: selectedDataStore,
    allParsedDataKeysForMarkersTab: allParsedDataKeys,
  } = useMapSettingsContext();
  const {
    markers: { data_source, detailView, selectedQuestionsMeta, layout },
  } = formContent;

  const selectedQuestionsArray = formContent?.markers.layout.fieldsOfInterest;
  const questionsMeta = useMemo(() => {
    return prepareQuestionsMeta(finalData);
  }, [finalData]);
  useEffect(() => {
    if (finalData?.length > 0 && selectedQuestionsArray) {
      const selectedQuestions = selectedQuestionsArray.map(item => item.value);
      const newlySelectedQuestions = selectedQuestions.filter(
        question =>
          !selectedQuestionsMeta.find(item => question === item.question)
      );
      const filteredQuestionsMeta = [
        ...selectedQuestionsMeta.filter(item =>
          selectedQuestions.includes(item.question)
        ),
        ...questionsMeta.filter(item =>
          newlySelectedQuestions.includes(item.question)
        ),
      ];

      setFormContent({
        ...formContent,
        markers: {
          ...formContent.markers,
          selectedQuestionsMeta: filteredQuestionsMeta,
        },
      });
    }
  }, [selectedQuestionsArray]);

  const handleDataSourceChange = (
    key: 'workflow' | 'data_store',
    value: string
  ) => {
    let updatedDataSource = {
      ...formContent.markers.data_source,
      [key]: value,
    };

    if (key === 'workflow') {
      updatedDataSource = {
        ...updatedDataSource,
        data_store: '',
      };
    }
    setFormContent({
      ...formContent,
      markers: {
        ...formContent.markers,
        selectedQuestionsMeta: [],
        data_source: updatedDataSource,
        layout: {
          format: 'lat/long',
          locationField: '',
          fieldsOfInterest: [{ value: '', label: '' }],
          visibilityZoomLevel: '0',
        },
        detailView: {
          ...detailView,
          displayFieldsOfInterest: [],
        },
      },
    });
    setIsDirty(true);
  };
  const updateColorForAnswerMarker = (color: string) => {
    const [questionIdx, answerIdx] = activeAnswerColorPickerIdx!;
    // RTK Query response objects are immutable, so deep clone is required. (actually it's good practise)
    const newQuestionsMeta = selectedQuestionsMeta.map((question, index) => {
      if (index === questionIdx) {
        return {
          ...question,
          answers: question.answers.map((answer, index) => {
            if (index === answerIdx) {
              return {
                ...answer,
                color: color,
              };
            }
            return answer;
          }),
        };
      }
      return question;
    });
    setFormContent({
      ...formContent,
      markers: {
        ...formContent.markers,
        selectedQuestionsMeta: newQuestionsMeta,
      },
    });
    setIsDirty(true);
  };

  type MarkerSectionKeys = {
    [K in keyof MapFormStateType['markers']]: keyof MapFormStateType['markers'][K];
  };

  const updateMarkerTab = <K extends keyof MapFormStateType['markers']>(
    section: K,
    key: MarkerSectionKeys[K],
    value: string | boolean | { value: string; label: string }[]
  ) => {
    setFormContent(formContent => ({
      ...formContent,
      markers: {
        ...formContent.markers,
        [section]: {
          ...(formContent.markers[section] as object),
          [key]: value,
        },
      },
    }));
    setIsDirty(true);
  };
  return (
    <div className="grid grid-cols-1 gap-3 xl:grid-cols-2">
      <div className="flex flex-col w-full space-y-4">
        <Card>
          <CardHeader>
            <CardTitle>Data Source</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-2">
              <Label>Workflow</Label>
              <Select
                value={data_source.workflow ?? ''}
                onValueChange={val => handleDataSourceChange('workflow', val)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Choose Workflow" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className={'overflow-y-auto max-h-96'}>
                    <SelectLabel>Workflows</SelectLabel>
                    {workflowData &&
                      workflowData.map(
                        (workflow: { value: string; text: string }) => (
                          <SelectItem
                            key={workflow.value}
                            value={workflow.value.toString()}
                          >
                            {workflow.text}
                          </SelectItem>
                        )
                      )}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex flex-col gap-y-2">
              <Label>Data Store</Label>
              <Select
                disabled={!selectedWorkflow}
                value={data_source.data_store ?? ''}
                onValueChange={val => handleDataSourceChange('data_store', val)}
              >
                <SelectTrigger>
                  <SelectValue
                    placeholder={
                      selectedWorkflow
                        ? 'Choose Datastore'
                        : 'Select workflow to choose data store'
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className={'overflow-y-auto max-h-96'}>
                    <SelectLabel>Data Stores</SelectLabel>
                    {dataStores &&
                      dataStores?.data?.data.map(
                        (
                          dataStore: { value: string; text: string },
                          index: number
                        ) => (
                          <SelectItem
                            key={`dataStore-${index} - ${dataStore.value}`}
                            value={dataStore.value}
                          >
                            {dataStore.text}
                          </SelectItem>
                        )
                      )}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </CardContent>
        </Card>
        {selectedWorkflow && selectedDataStore && finalData?.length > 0 && (
          <div className="flex flex-col w-full space-y-4">
            <Card>
              <CardHeader>
                <CardTitle>Detail View</CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col gap-y-6">
                <div className="flex flex-col gap-y-2">
                  <Label className="text-base">Include Value Field</Label>
                  <Switch
                    checked={detailView.includeValueField}
                    onCheckedChange={checked => {
                      updateMarkerTab(
                        'detailView',
                        'includeValueField',
                        checked
                      );
                    }}
                    checkedLabel="On"
                    unCheckedLabel="Off"
                  />
                </div>
                <div className="flex flex-col gap-y-2">
                  <Label>Fields Of Interest</Label>
                  <ReactSelect
                    placeholder="Select Fields of Interest"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={allParsedDataKeys.map(key => ({
                      value: key,
                      label: key,
                    }))}
                    isMulti
                    menuPlacement="top"
                    value={detailView.displayFieldsOfInterest.filter(
                      item => item.value
                    )}
                    onChange={val => {
                      updateMarkerTab(
                        'detailView',
                        'displayFieldsOfInterest',
                        val.map(item => ({
                          value: item.value,
                          label: item.label,
                        }))
                      );
                    }}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
      <div className="flex flex-col w-full space-y-4">
        {selectedWorkflow && selectedDataStore && finalData?.length > 0 && (
          <Card>
            <CardHeader>
              <CardTitle>Layout</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-y-4">
              <div className="space-y-4">
                <div className="flex justify-between space-x-4">
                  <div className="flex flex-col w-1/2 gap-y-2">
                    <Label>Location Field</Label>
                    <Select
                      value={layout.locationField ?? ''}
                      onValueChange={val => {
                        updateMarkerTab('layout', 'locationField', val);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select Field" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup className={'overflow-y-auto max-h-96'}>
                          <SelectLabel>Fields</SelectLabel>

                          {allParsedDataKeys.map((item, index) => (
                            <SelectItem
                              key={`initial_sort-${index} - ${item}`}
                              value={item}
                            >
                              {' '}
                              {item}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex flex-col w-1/2 gap-y-2">
                    <Label>Format</Label>
                    <Select
                      value={layout.format ?? ''}
                      onValueChange={val => {
                        updateMarkerTab('layout', 'format', val);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select Format" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="postcode">Postcode</SelectItem>
                        <SelectItem value="lat/long">Lat/Long</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <Label>Fields Of Interest</Label>
                <ReactSelect
                  placeholder="Select Fields of Interest"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={allParsedDataKeys.map(key => ({
                    value: key,
                    label: key,
                  }))}
                  isMulti
                  value={layout.fieldsOfInterest.filter(item => item.value)}
                  onChange={val => {
                    updateMarkerTab(
                      'layout',
                      'fieldsOfInterest',
                      val.map(item => ({
                        value: item.value,
                        label: item.label,
                      }))
                    );
                  }}
                />
              </div>
              <div className="flex flex-col w-1/3 gap-y-2">
                <Label>Visibility Zoom Level</Label>
                <Select
                  value={layout.visibilityZoomLevel ?? '0'}
                  onValueChange={val => {
                    updateMarkerTab('layout', 'visibilityZoomLevel', val);
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Zoom Level" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup className={'overflow-y-auto max-h-96'}>
                      <SelectLabel>Zoom Levels</SelectLabel>
                      {Array.from({ length: 19 }, (_, i) => i.toString()).map(
                        level => (
                          <SelectItem key={level} value={level.toString()}>
                            {level}{' '}
                            {level === '0'
                              ? '(minimum zoom)'
                              : level === '18'
                              ? '(max zoom)'
                              : ''}
                          </SelectItem>
                        )
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </CardContent>
          </Card>
        )}
        {selectedWorkflow && selectedDataStore && finalData?.length > 0 && (
          <Card className="relative">
            <CardHeader>
              <CardTitle>Styling</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col gap-y-4">
                {selectedQuestionsMeta.map((question, questionIndex) => {
                  return (
                    <div key={question.question}>
                      <h3 className="text-[16px] font-bold border-y my-1">
                        {question.question}
                      </h3>
                      <div className="flex flex-col divide-y gap-y-2">
                        {question.answers
                          .filter(answer => answer.answer)
                          .map((answer: any, answerIndex: number) => (
                            <div
                              key={answerIndex}
                              className="relative flex items-center justify-between px-3 pt-1"
                            >
                              <span className="text-[14px]">
                                {answer.answer}
                              </span>
                              <span
                                onClick={() =>
                                  setActiveAnswerColorPickerIdx([
                                    questionIndex,
                                    answerIndex,
                                  ])
                                }
                                className={`w-[30px] h-[30px] rounded-md cursor-pointer`}
                                style={{ backgroundColor: answer.color }}
                              ></span>
                              {activeAnswerColorPickerIdx &&
                                activeAnswerColorPickerIdx[0] ===
                                  questionIndex &&
                                activeAnswerColorPickerIdx[1] ===
                                  answerIndex && (
                                  <div className="absolute top-0 z-10 left-1/2">
                                    <div
                                      className="fixed inset-0 bg-white opacity-20"
                                      onClick={() =>
                                        setActiveAnswerColorPickerIdx(undefined)
                                      }
                                    ></div>
                                    <div className="inset-0">
                                      <SketchPicker
                                        disableAlpha={true}
                                        presetColors={[
                                          ...defaultDataStyling,
                                          'transparent',
                                        ]}
                                        width="200px"
                                        color={answer.color}
                                        onChange={color =>
                                          updateColorForAnswerMarker(color.hex)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </CardContent>
          </Card>
        )}
      </div>
      {finalData?.length === 0 && <EmptyDataStore />}
    </div>
  );
};
