import { GridQuestion, Question } from '../Helpers/Types';
import { Input } from '@/tcomponents/ui/input';
import { Button, IconButton } from '@/tcomponents/ui/button';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Label } from '@/tcomponents/ui/label';
import { cn } from '@/lib/utils';

interface GridQuestionProps {
  question: GridQuestion;
  updateQuestion: (data: Question) => void;
}

export const GridQue = (props: GridQuestionProps) => {
  const { question, updateQuestion } = props;

  const addNewAnswer = () => {
    const newAnswer = '';
    updateQuestion({
      ...question,
      answers: [...question.answers, newAnswer],
    });
  };
  const addNewTopic = () => {
    const newTopic = {
      label: '',
      key: '',
    };
    updateQuestion({
      ...question,
      topics: [...question.topics, newTopic],
    });
  };

  const deleteAnswer = (index: number) => {
    const newAnswers = question.answers.filter((_, i) => i !== index);
    updateQuestion({ ...question, answers: newAnswers });
  };

  const deleteTopic = (index: number) => {
    const newTopics = question.topics.filter((_, i) => i !== index);
    updateQuestion({ ...question, topics: newTopics });
  };

  const updateAnswer = (index: number, value: string) => {
    const newAnswers = question.answers.map((ans, i) =>
      i === index ? value : ans
    );
    updateQuestion({ ...question, answers: newAnswers });
  };

  const updateTopic = (index: number, value: string) => {
    const newTopics = question.topics.map((top, i) =>
      i === index ? { ...top, label: value, key: value } : top
    );
    updateQuestion({ ...question, topics: newTopics });
  };

  return (
    <div className="flex flex-col-reverse items-start w-full gap-4 p-4 lg:flex-row">
      <div className="flex flex-col w-full divide-y lg:pl-4 lg:w-1/2">
        <Label>Topics</Label>
        <div className="flex flex-col mt-1 divide-y gap-y-0">
          {question?.topics?.map((topic, index) => (
            <div
              className={cn('flex items-center justify-between py-2 gap-x-4', {
                '!border-b': question.topics.length - 1 === index,
              })}
              key={index}
            >
              <Input
                value={topic.label}
                onChange={e => updateTopic(index, e.target.value)}
              />
              <IconButton
                icon={faXmark}
                onClick={() => deleteTopic(index)}
                tooltip="Delete Topic"
                testingAttribute="delete-topic"
                tabIndex={-1}
                className="p-2 text-2xl text-white bg-red-500 rounded-lg fa-2xl"
              />
            </div>
          ))}
        </div>
        <Button
          className="w-full mt-2.5 border-gray-300 bg-info"
          onClick={addNewTopic}
        >
          + Add Topic
        </Button>
      </div>
      <div className="flex flex-col w-full divide-y lg:w-1/2">
        <Label>Answers</Label>
        <div className="flex flex-col mt-1 divide-y gap-y-0">
          {question?.answers?.map((answer, index) => (
            <div
              className={cn('flex items-center justify-between py-2 gap-x-4', {
                '!border-b': question.answers.length - 1 === index,
              })}
              key={index}
            >
              <Input
                value={answer}
                onChange={e => updateAnswer(index, e.target.value)}
              />
              <IconButton
                icon={faXmark}
                onClick={() => deleteAnswer(index)}
                tooltip="Delete Answer"
                testingAttribute="delete-answer"
                tabIndex={-1}
                className="p-2 text-2xl text-white bg-red-500 rounded-lg fa-2xl"
              />
            </div>
          ))}
        </div>
        <Button
          className="w-full mt-2.5 border-gray-300 bg-info"
          onClick={addNewAnswer}
        >
          + Add Answer
        </Button>
      </div>
    </div>
  );
};
