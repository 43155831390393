import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@tcomponents/ui/select';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/tcomponents/ui/popover';
import { PopoverClose } from '@radix-ui/react-popover';
import { Card, CardContent } from '@/tcomponents/ui/card';
import { Question, Steps } from '../Helpers/Types';
import { getQuestionComponent } from '../Helpers/getQuestionComponent';
import { Label } from '@/tcomponents/ui/label';
import { Input } from '@/tcomponents/ui/input';
import {
  faEllipsisVertical,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { Button, IconButton } from '@tcomponents/ui/button';

type QuestionCardProps = {
  steps: Steps;
  question: Question;
  updateStep: (question: Question) => void;
  handleDeleteStep: (id: string | number) => void;
  handleQueTypeChange: (val: Question['type'], question: Question) => void;
};

export const QuestionCard = (props: QuestionCardProps) => {
  const { question, updateStep, handleDeleteStep, handleQueTypeChange, steps } =
    props;
  return (
    <Card className="relative border-none shadow-none grow">
      <CardContent className="p-6 space-y-5">
        {/* question title, type and delete button section */}
        <div>
          {/* question and question type */}
          <div className="flex justify-between pr-5 gap-x-[55px] grow">
            <div className="flex flex-col grow gap-y-1 ">
              <Label>Question</Label>
              <Input
                value={question.title}
                onChange={e => {
                  const newQuestion = {
                    ...question,
                    title: e.target.value,
                    key: e.target.value,
                  };
                  updateStep(newQuestion);
                }}
              />
            </div>
            <div className="flex flex-col gap-y-1 w-[170px]">
              <Label>Type</Label>
              <Select
                defaultValue={question.type}
                value={question.type}
                onValueChange={(val: Question['type']) =>
                  handleQueTypeChange(val, question as Question)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select Type" />
                </SelectTrigger>
                <SelectContent className="w-full">
                  <SelectGroup className={'overflow-y-auto max-h-96 w-full'}>
                    <SelectItem key="choice" value="choice">
                      Choice
                    </SelectItem>
                    <SelectItem key="paragraph" value="paragraph">
                      Paragraph
                    </SelectItem>
                    <SelectItem key="form" value="form">
                      Form
                    </SelectItem>
                    <SelectItem key="number" value="number">
                      Number
                    </SelectItem>
                    <SelectItem key="order" value="order">
                      Order
                    </SelectItem>
                    <SelectItem key="grid" value="grid" disabled={true}>
                      Grid
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          {/* popover to delete question */}
          <div className="absolute top-4 right-4">
            <Popover>
              <PopoverTrigger tabIndex={-1}>
                <IconButton
                  tabIndex={-1}
                  icon={faEllipsisVertical}
                  tooltip="Actions"
                  className="pl-5 py-[3px]"
                />
              </PopoverTrigger>
              <PopoverContent side="left" className="p-0 w-[100px]">
                <PopoverClose>
                  <Button
                    icon={faTrashCan}
                    disabled={steps.length === 1}
                    onClick={() => handleDeleteStep(question.id)}
                    variant="danger"
                    className="font-normal text-[14px] w-full "
                  >
                    Delete
                  </Button>
                </PopoverClose>
              </PopoverContent>
            </Popover>
          </div>
        </div>
        {/* Instruction field */}
        <div className="flex flex-col grow gap-y-1">
          <Label>
            Instruction <span className="text-gray-400">(optional)</span>
          </Label>
          <Input
            value={question.instruction ?? ''}
            onChange={e => {
              const newQuestion = {
                ...question,
                instruction: e.target.value,
              };
              updateStep(newQuestion);
            }}
          />
        </div>
        {/* remaining form content based on the type of the question */}
        <div className="">
          {getQuestionComponent(question as Question, updateStep)}
        </div>
      </CardContent>
    </Card>
  );
};
