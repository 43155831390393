import { useGetMapShapeQuery } from '@/store/services/mapManagerApi';
import { useState, useEffect } from 'react';
import shp, { FeatureCollectionWithFilename } from 'shpjs';
import { ShapeFileWithDataProp } from './ShapeFileWithDataProp';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { MapFeatureDetails } from '@/types/feature/featureDetails';
import { GeoJsonObject } from 'geojson';
import { useMapViewContext } from '../mapContext/MapViewProvider';

type OverlayRenderProps = {
  overlay: MapFeatureDetails['overlays'][0];
};
export type OverlayData = GeoJsonObject | FeatureCollectionWithFilename;

const OverlayRender: React.FC<OverlayRenderProps> = (
  props: OverlayRenderProps
) => {
  const { overlay } = props;
  const {
    overlayVisibility,
    setOverlaysLoadingState,
    isPublicView,
    mapShapesResponseFromProps,
  } = useMapViewContext();
  const [overlayData, setOverlayData] = useState<OverlayData | undefined>();

  const overlayShapeFileId = overlay.shapeFile;
  const { data: overlayResponseFromApi } = useGetMapShapeQuery(
    Number(overlayShapeFileId),
    {
      skip:
        !overlayVisibility[overlay.id] || !overlayShapeFileId || isPublicView,
    }
  );
  const overlayResponse = isPublicView
    ? mapShapesResponseFromProps?.filter(
        (item: any) => item.id === Number(overlayShapeFileId)
      )[0]
    : overlayResponseFromApi?.data;
  const url = overlayResponse?.target_url;
  const fileType = overlayResponse?.file_type;
  const shouldRender = overlayVisibility[overlay.id] && url && fileType;

  useEffect(() => {
    const fetchOverlayData = async () => {
      try {
        let data: OverlayData | undefined;
        setOverlaysLoadingState({ id: overlay.id, loading: true });
        const { dismiss: dismissToast } = toast({
          description: `Loading ${overlay.title}  this may take a few seconds…`,
        });
        if (fileType === 'Shape') {
          data = (await shp(url)) as FeatureCollectionWithFilename;
        } else if (fileType === 'GeoJSON') {
          const res = await fetch(url);
          data = (await res.json()) as GeoJsonObject;
        } else {
          console.error('Unsupported file type.');
          return;
        }
        dismissToast();
        setOverlayData(data);
        setOverlaysLoadingState({ id: overlay.id, loading: false });
      } catch (err) {
        console.error(err);
        toast({
          title: 'Oh no!',
          variant: 'destructive',
          description: `Failed to fetch file for: ${overlay.title}`,
          duration: 10000,
        });
      }
    };

    if (shouldRender && !overlayData) {
      fetchOverlayData();
    }
  }, [overlay, url, fileType, shouldRender]);
  return (
    shouldRender && (
      <ShapeFileWithDataProp
        key={overlay.id}
        data={overlayData}
        fileType={fileType}
        layer={overlay}
      />
    )
  );
};

export default OverlayRender;
