import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createErrorHandler } from '../../utils/createErrorHandler';
import { UserDetailsInterface } from '@/types/interfaces/api-response/UserDetailsInterface';
import { roleManagementApi } from '../roleManagementApi';
import {
  UserAdministrationResponseInterface,
  UserAdministrationsStatsResponseInterface,
} from '@/types/interfaces/api-response/UserAdministrationResponseInterface';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['User', 'AdministrationUserList', 'AdministrationUserStats'],
  endpoints: builder => ({
    getAdministrationUserList: builder.query<
      { data: UserAdministrationResponseInterface },
      void
    >({
      query: () => {
        return {
          url: '/users',
          method: 'GET',
        };
      },
      providesTags: ['AdministrationUserList'],
    }),

    getUserStats: builder.query<
      UserAdministrationsStatsResponseInterface,
      void
    >({
      query: () => {
        return {
          url: '/users/stats',
          method: 'GET',
        };
      },
      providesTags: ['AdministrationUserStats'],
    }),

    getUserDetails: builder.query<{ data: UserDetailsInterface }, number>({
      query: (id: number) => {
        return {
          url: '/users/' + id,
          method: 'GET',
        };
      },
      onQueryStarted: createErrorHandler(
        'There was a problem loading user details'
      ),
      providesTags: ['User'],
    }),

    getEnabledUsersForSelector: builder.query<
      { data: { label: string; value: number }[] },
      void
    >({
      query: () => {
        return {
          url: '/users/available-users-for-selector',
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0.01,
    }),

    updateUserDetails: builder.mutation({
      query: ({ id, name, email, organisations, enabled, roles, expiry }) => {
        return {
          url: '/users/' + id,
          method: 'PUT',
          body: {
            email,
            name,
            organisations,
            enabled,
            roles,
            expiry_date: expiry,
          },
        };
      },
      invalidatesTags: ['User', 'AdministrationUserList'],
      // we need to invalidate the roleManagementApi tags- rolesList and roleDetails after updating the user details
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          roleManagementApi.util.invalidateTags(['rolesList', 'roleDetails'])
        );
      },
    }),
    updateStatus: builder.mutation({
      query: (values: { id: string | number; status: boolean }) => {
        return {
          url: 'users/' + values.id + '/update-status',
          method: 'POST',
          body: {
            enabled: values.status,
          },
        };
      },
      invalidatesTags: [
        'User',
        'AdministrationUserList',
        'AdministrationUserStats',
      ],
    }),

    unlockUser: builder.mutation({
      query: (id: string | number) => {
        return {
          url: 'users/' + id + '/unlock',
          method: 'GET',
        };
      },
      invalidatesTags: ['User', 'AdministrationUserList'],
    }),
  }),
});

export const {
  useGetAdministrationUserListQuery,
  useGetUserDetailsQuery,
  useUpdateUserDetailsMutation,
  useUpdateStatusMutation,
  useUnlockUserMutation,
  useGetEnabledUsersForSelectorQuery,
  useGetUserStatsQuery,
} = userApi;
