import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { SearchResult } from 'leaflet-geosearch/dist/providers/provider';
import { geoJSON } from 'leaflet';
import { FeatureCollection, Feature } from 'geojson';

export class CustomMapSearchbarProvider extends OpenStreetMapProvider {
  searchProperty: string;
  shapeFileData: FeatureCollection;

  constructor(
    options: any,
    searchProperty: string,
    shapeFileData?: FeatureCollection,
    private ignoreOpenStreetResults?: boolean
  ) {
    super({
      ...options,
      params: {
        countrycodes: 'gb',
      },
    });
    this.searchProperty = searchProperty;
    this.shapeFileData = shapeFileData ?? {
      type: 'FeatureCollection',
      features: [],
    };
  }

  async search({ query }: { query: string }): Promise<SearchResult[]> {
    // these are the results from the shape file
    const shapeFileFeatures = this.shapeFileData.features;
    const searchProperty = this.searchProperty.toLocaleLowerCase() ?? '';

    let formattedShapeFileResults: SearchResult[] = [];

    if (shapeFileFeatures && searchProperty) {
      const filteredShapeFileFeatures = shapeFileFeatures.filter(
        (feature: Feature) => {
          const propertyValue = feature?.properties?.[searchProperty];
          return propertyValue.toLowerCase().includes(query.toLowerCase());
        }
      );

      formattedShapeFileResults = filteredShapeFileFeatures.map(
        (feature: Feature) => {
          const geoJson = geoJSON(feature);
          const bounds = geoJson.getBounds();
          const latLng = bounds.getCenter();
          const label = feature?.properties?.[searchProperty];
          return {
            x: latLng.lng,
            y: latLng.lat,
            label: label,
            bounds:
              [
                [bounds.getSouthWest().lat, bounds.getSouthWest().lng],
                [bounds.getNorthEast().lat, bounds.getNorthEast().lng],
              ] ?? null,
            raw: {
              source: 'shapeFile',
            },
          } as SearchResult;
        }
      );
    }
    // these are the results from the open street map
    const resultsFromOpenStreetMap = this.ignoreOpenStreetResults
      ? []
      : await super.search({ query });

    const combinedResults = [
      ...formattedShapeFileResults,
      ...resultsFromOpenStreetMap,
    ];
    return combinedResults;
  }
}
