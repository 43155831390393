import { useState, useEffect, useContext } from 'react';
import { FullscreenContext } from '@/context/FullscreenContext';
import { FeatureCollection, Feature } from 'geojson';
import { LatLngBounds } from 'leaflet';
import { useCreateMarkers } from '../hooks/useCreateMarkers';
import { useHandleBoundaryVisibility } from '../hooks/useHandleBoundaryVisibility';
import { useHandleOverlayVisibility } from '../hooks/useHandleOverlayVisibility';
import { useMapConfig } from '../hooks/useMapConfig';
import { useMapData } from '../hooks/useMapData';
import { useQueAndAnsVisibility } from '../hooks/useQueAndAnsVisibility';
import { MapViewProviderProps } from './MapViewProvider';

const useMapViewProviderValues = (props: MapViewProviderProps) => {
  const {
    id,
    isPublicView,
    mapConfigFromProps,
    mapDataFromProps,
    mapShapesResponseFromProps,
    aggregatedDataFromProps,
    project,
    feature,
    setLastUpdatedForMap,
  } = props;

  const [currentZoom, setCurrentZoom] = useState(0);
  const [enabledBoundaryShapeFileData, setEnabledBoundaryShapeFileData] =
    useState<FeatureCollection | undefined>(undefined);
  const [initialMapBoundsOnLoad, setInitialMapBoundsOnLoad] = useState<
    LatLngBounds | undefined
  >(undefined);
  const [activeBoundaryFeature, setActiveBoundaryFeature] = useState<
    Feature | undefined
  >(undefined);

  const { handle } = useContext(FullscreenContext);
  const isFullscreen = handle.active;

  const {
    mapConfigResponse: mapConfigFromApi,
    selectedQuestionsMeta: selectedQuestionsMetaFromApi,
    includeValueField: includeValueFieldFromApi,
  } = useMapConfig(id as string, isPublicView);

  const mapConfigResponse = mapConfigFromProps || mapConfigFromApi;
  const selectedQuestionsMeta = mapConfigFromProps
    ? mapConfigFromProps.data.selectedQuestionsMeta
    : selectedQuestionsMetaFromApi;
  const includeValueField = mapConfigFromProps
    ? mapConfigFromProps.data.detailView.includeValueField
    : includeValueFieldFromApi ?? false;

  const cluster = mapConfigResponse?.data?.cluster ?? true;
  const overlays = mapConfigResponse?.data?.overlays;
  const boundaries = mapConfigResponse?.data?.boundaries;
  const shouldShowSearchBar =
    mapConfigResponse?.data?.display?.searchBar !== false && !isFullscreen;

  const {
    overlayVisibility,
    toggleOverlayVisibility,
    overlaysLoading,
    setOverlaysLoadingState,
  } = useHandleOverlayVisibility(overlays);
  const {
    boundariesVisibility,
    toggleBoundaryVisibility,
    boundariesLoading,
    setBoundariesLoadingState,
    visibleBoundaryConfigData,
  } = useHandleBoundaryVisibility(boundaries, setActiveBoundaryFeature);
  const {
    mapDataResponse,
    information,
    sources,
    postcodesWithCoords,
    initialCoordsForMap,
  } = useMapData(
    id as string,
    mapConfigResponse,
    isPublicView,
    mapDataFromProps
  );
  const {
    questionsAndAnswersVisibility,
    visibleQuestion,
    toggleQuestionAndAnswersVisibility,
  } = useQueAndAnsVisibility(selectedQuestionsMeta);
  const { markersData } = useCreateMarkers(
    mapConfigResponse,
    mapDataResponse,
    includeValueField,
    selectedQuestionsMeta,
    questionsAndAnswersVisibility,
    visibleQuestion,
    postcodesWithCoords,
    activeBoundaryFeature,
    visibleBoundaryConfigData,
    id
  );

  const haveMarkers = !!selectedQuestionsMeta?.length;
  const visibilityZoomLevel =
    mapConfigResponse?.data?.layout?.visibilityZoomLevel ?? 0;
  const shouldShowMarkers = currentZoom >= Number(visibilityZoomLevel);

  useEffect(() => {
    setLastUpdatedForMap &&
      setLastUpdatedForMap(mapDataResponse?.last_updated || '');
  }, [mapDataResponse?.last_updated, setLastUpdatedForMap]);

  // use effect to reset the states to default when the id of the map changes
  useEffect(() => {
    setEnabledBoundaryShapeFileData(undefined);
    setActiveBoundaryFeature(undefined);
  }, [id]);

  return {
    setCurrentZoom,
    enabledBoundaryShapeFileData,
    setEnabledBoundaryShapeFileData,
    mapConfigResponse,
    selectedQuestionsMeta,
    overlayVisibility,
    toggleOverlayVisibility,
    overlaysLoading,
    setOverlaysLoadingState,
    boundariesVisibility,
    toggleBoundaryVisibility,
    boundariesLoading,
    setBoundariesLoadingState,
    visibleBoundaryConfigData,
    information,
    sources,
    initialCoordsForMap,
    questionsAndAnswersVisibility,
    toggleQuestionAndAnswersVisibility,
    markersData,
    haveMarkers,
    shouldShowMarkers,
    shouldShowSearchBar,
    mapShapesResponseFromProps,
    aggregatedDataFromProps,
    cluster,
    overlays,
    boundaries,
    isPublicView,
    project,
    feature,
    setInitialMapBoundsOnLoad,
    initialMapBoundsOnLoad,
    activeBoundaryFeature,
    setActiveBoundaryFeature,
  };
};

export default useMapViewProviderValues;
