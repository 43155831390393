import { useEffect } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import { GeoSearchControl } from 'leaflet-geosearch';
import { SearchResult } from 'leaflet-geosearch/dist/providers/provider';
import { CustomMapSearchbarProvider } from '../utils/CustomMapSeachbarProvider';
import { shapeFileFeatureKeysToLowerCase } from '../helpers/caseConversion';
import { useMapViewContext } from '../mapContext/MapViewProvider';

interface Selection {
  query: string;
  data?: SearchResult;
}

// custom logic here to determine if the marker should be shown, we don't want to show the marker if the result is from the shape file
const showCustomMarker = (result: SearchResult) => {
  return result.raw.source !== 'shapeFile';
};

export const MapSearchBar = () => {
  const {
    enabledBoundaryShapeFileData,
    visibleBoundaryConfigData,
    setActiveBoundaryFeature,
  } = useMapViewContext();
  const searchProperty = visibleBoundaryConfigData?.searchProperty;
  const visibleBoundaryTitle = visibleBoundaryConfigData?.title;
  const map = useMap();
  const normalizedShapeFileData = shapeFileFeatureKeysToLowerCase(
    enabledBoundaryShapeFileData
  );

  const ignoreOpenStreetSearchResults =
    visibleBoundaryConfigData?.restrictSearch;

  const provider = new CustomMapSearchbarProvider(
    {},
    searchProperty ?? '',
    normalizedShapeFileData,
    ignoreOpenStreetSearchResults
  );

  const customIcon = L.divIcon({
    className: 'border-none outline-none',
    html: `<i class="fas fa-crosshairs text-2xl"></i>`,
  });

  const searchBar = GeoSearchControl({
    provider: provider,
    style: 'bar',
    animateZoom: true,
    marker: {
      icon: customIcon,
    },
    resultFormat: (result: { result: SearchResult }) => {
      return result.result.raw.source === 'shapeFile'
        ? `${result.result.label} - ${visibleBoundaryTitle}`
        : result.result.label;
    },
    searchLabel: 'Find Postcode or Location',
    notFoundMessage: 'No matching location could be found',
    maxMarkers: 1,
    maxSuggestions: 10,
  });

  searchBar.onSubmit = (result: Selection) => {
    searchBar.resultList.clear();
    if (result.data && result.data.bounds) {
      map.fitBounds(result.data.bounds);
      if (showCustomMarker(result.data)) {
        searchBar.addMarker(result.data, result);
      }
    }
    if (enabledBoundaryShapeFileData && searchProperty) {
      const selectedFeature = enabledBoundaryShapeFileData.features.find(
        (feature: any) =>
          feature.properties[searchProperty.toLowerCase()] === result.query
      );

      if (selectedFeature) {
        setActiveBoundaryFeature(selectedFeature);
      }
    }
  };

  useEffect(() => {
    if (!map) return;

    map.addControl(searchBar);

    // we are setting the global classes to remove the icon: In index.css- [data-lpignore] ~ div[data-lastpass-icon-root] {display: none;}, means if the input has data-lpignore attribute then hide the icon
    const searchInput = document.querySelector(
      '.leaflet-control-geosearch input'
    );
    if (searchInput) {
      searchInput.setAttribute('data-lpignore', 'true');
    }

    return () => {
      map.removeControl(searchBar);
    };
  }, [map, enabledBoundaryShapeFileData, searchProperty]);

  return null;
};
