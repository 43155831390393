import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L, { Control, Layer } from 'leaflet';
import 'leaflet-choropleth';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { MapFeatureDetails } from '@/types/feature/featureDetails';
import { FeatureCollection, Feature } from 'geojson';
import { formatMailtoString } from '../helpers/formatMailToString';
import { useActiveLayerInteractions } from '../hooks/useActiveLayerInteractions';
import { useMapViewContext } from '../mapContext/MapViewProvider';

type Props = {
  shapeFiledata: FeatureCollection | undefined;
  layerData: MapFeatureDetails['boundaries'][0];
  dataStoreData?: any;
};

export const Choropleth = (props: Props) => {
  const { shapeFiledata, layerData, dataStoreData } = props;
  const map = useMap();
  const { setActiveBoundaryFeature, activeBoundaryFeature } =
    useMapViewContext();
  const layerOpacity = layerData.opacity ?? 0.5;
  const {
    handleLayerHighlightOnMouseOver,
    handleLayerResetOnMouseOut,
    handleActiveLayerStyling,
    resetLayerStylesOnDeselection,
  } = useActiveLayerInteractions(layerOpacity);

  useEffect(() => {
    if (!shapeFiledata || !map || !dataStoreData || !layerData) return;
    // Normalize the casing of the properties in shapeFiledata to lowercase
    shapeFiledata.features = shapeFiledata.features.map((feature: Feature) => {
      if (feature.properties) {
        feature.properties = Object.fromEntries(
          Object.entries(feature.properties).map(([key, value]) => [
            key.toLowerCase(),
            value,
          ])
        );
      }
      return feature;
    });

    // Create a lookup object from dataStoreData
    const dataStoreLookup = dataStoreData.reduce(
      (
        acc: Record<string, number>,
        curr: { _name: string; _value: number }
      ) => {
        if (Number(curr._value) > 0) {
          acc[curr._name] = Number(curr._value);
        }
        return acc;
      },
      {}
    );

    const lowerCaseKeyProperty = layerData.keyProperty?.toLowerCase();
    // Preprocess GeoJSON data
    shapeFiledata.features = shapeFiledata.features.map((feature: Feature) => {
      if (!feature.properties) {
        feature.properties = {};
      }

      const matchingValue =
        dataStoreLookup[feature.properties[lowerCaseKeyProperty]];

      if (matchingValue) {
        feature.properties['heatmapValue'] = Number(matchingValue);
      }
      // here we are adding additional properties from dataStoreData to the shapeFiledata
      const newPropertiesToAdd = dataStoreData.find(
        (data: any) => data._name === feature.properties?.[lowerCaseKeyProperty]
      );

      if (newPropertiesToAdd) {
        const { _name, _value, ...restOfProperties } = newPropertiesToAdd;
        const lowerCaseRestOfProperties = Object.fromEntries(
          Object.entries(restOfProperties).map(([key, value]) => [
            key.toLowerCase(),
            value,
          ])
        );
        feature.properties = {
          ...feature.properties,
          ...lowerCaseRestOfProperties,
        };
      }
      return feature;
    });

    // @ts-ignore - there are no types available for leaflet-choropleth
    const geo = L.choropleth(shapeFiledata, {
      scale: [layerData.minColor, layerData.maxColor],
      valueProperty: 'heatmapValue',
      steps: 10,
      mode: 'l',
      style: (feature: any) => {
        const hasHeatmapValue =
          feature.properties && feature.properties.heatmapValue !== undefined;
        return {
          weight: 1,
          color: '#ccc', // Border color
          fillColor: hasHeatmapValue ? undefined : layerData.color ?? '#ccc', // fill color will be handled by choropleth if heatmapValue is present otherwise use the color from the layerData
          fillOpacity: layerOpacity,
        };
      },

      onEachFeature: function (f: Feature, l: Layer) {
        l.on({
          click: () => setActiveBoundaryFeature(f),
          mouseover: handleLayerHighlightOnMouseOver,
          mouseout: handleLayerResetOnMouseOut,
        });
        if (f.properties) {
          // Get the hoverProperties from the overlay and convert to lower case, we had to do this because in overlay properties are in different case
          const hoverProperties = layerData.hoverProperties
            ?.filter(prop => prop.label !== null && prop.value !== null)
            ?.map(prop => prop.value.toLowerCase());

          // hover properties from dataStoreData
          const hoverPropertiesDataStore =
            layerData?.dataStoreHoverKeys
              ?.filter(prop => prop.value)
              ?.map(prop => prop.value.toLocaleLowerCase()) ?? [];

          const finalHoverProperties = [
            ...hoverProperties,
            ...hoverPropertiesDataStore,
          ];
          // Filter the properties based on hoverProperties
          if (finalHoverProperties.length > 0) {
            // Create a new object with lower case keys
            const lowerCaseProperties = Object.fromEntries(
              Object.entries(f.properties).map(([key, value]) => [
                key.toLowerCase(),
                value,
              ])
            );
            const out = finalHoverProperties
              ?.filter((key: string) => key in lowerCaseProperties)
              ?.map(
                (key: string, index: number) =>
                  `<strong key=${index}>${
                    key.charAt(0).toUpperCase() + key.slice(1)
                  }</strong> <br />
                             ${formatMailtoString(
                               lowerCaseProperties[key]
                             )} <br />`
              );
            out.push(`<strong>${layerData.units ?? 'Unit'}</strong> <br />
                                     ${
                                       lowerCaseProperties['heatmapvalue'] ??
                                       'unavailable'
                                     } <br />
                                    `);
            l.bindPopup(out.join('<br />'));
          }
        }
      },
    }).addTo(map);

    const loadFile = () => {
      try {
        geo.addData(shapeFiledata);
        geo.bringToBack();
      } catch (err) {
        console.error(err);
        toast({
          title: 'Oh no!',
          variant: 'destructive',
          description: `Failed to load map layer file: ${layerData.title}`,
        });
      }
    };

    loadFile();

    // this logic is to handle the selection/deselection of active layer on the map
    if (activeBoundaryFeature) {
      handleActiveLayerStyling(geo);
      resetLayerStylesOnDeselection(geo);
    }

    return () => {
      map.removeLayer(geo);
    };
  }, [shapeFiledata, map, layerData, dataStoreData, activeBoundaryFeature]);

  useEffect(() => {
    if (!shapeFiledata || !map || !dataStoreData || !layerData) return;
    const legend = new Control({ position: 'bottomright' });
    let maxValue = 0;
    let minValue = 0;
    if (dataStoreData) {
      maxValue = Math.max(
        ...dataStoreData.map((item: { _value: number }) => item._value)
      );
      minValue = Math.min(
        ...dataStoreData.map((item: { _value: number }) => item._value)
      );
    }
    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'legend');
      div.style.padding = '15px';
      div.style.height = '250px';
      div.style.marginBottom = '40px';
      div.style.marginRight = '40px';
      div.style.border = '1px solid #ccc';
      div.style.borderRadius = '5px';
      div.style.background = `white`;
      div.innerHTML = `
        <div class='flex flex-col h-full items-center gap-y-2 max-w-[150px]'>
         <h5 class="text-center">${layerData.units ?? 'Unit'}</h5>
            <div class='flex h-full '>
            <div class='w-[30px] opacity-80' style='background: linear-gradient(${
              layerData.maxColor
            }, ${layerData.minColor})'> </div>
            <div class='flex flex-col justify-between'>
                 <p class='ml-1 font-bold'>${maxValue}</p>
                 <p class='ml-1 font-bold'>${minValue}</p>
            </div>
             </div>
        </div>
        `;

      return div;
    };

    legend.addTo(map);

    return () => {
      map.removeControl(legend);
    };
  }, [shapeFiledata, map, layerData, dataStoreData]);

  return null;
};
