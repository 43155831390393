import React, { FC, useEffect, useRef, ReactNode, Ref } from 'react';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { merge } from 'lodash';
import Select from './table/filter/Select';
import Input from './table/filter/Input';
import { Button } from '@tcomponents/ui/button';
import {
  faArrowsRotate,
  faFileArrowDown,
} from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import { navigateToLogout } from '@/utils/navigateToLogout';
import { cn } from '@/lib/utils';
import * as XLSX from 'xlsx';
import {
  boolAsYesNo,
  objectArrayToCsv,
  stringArrayAsCommaValues,
} from '@/pages/dashboard/projects/utils/TabulatorFormattersAndAccessors';

interface IFilterProps {
  filterName: string;
  filterClass: string;
  type: string;
  placeholder?: string;
  options?: any;
  multiselect?: boolean;
  searchable?: boolean;
  filterOn: any;
}

interface ITableProps {
  id: string;
  config?: any;
  actions?: ReactNode;
  aboveTable?: ReactNode;
  updated?: boolean;
  forwardedRef?: Ref<any>;
  refreshCallback?: () => void;
  shouldPaginate?: boolean;
}

interface ILaravelPaginator {
  data: object;
  links: object;
  meta: any;
}

const TabulatorTable: FC<ITableProps> = ({
  id,
  config,
  actions,
  aboveTable,
  updated = false,
  forwardedRef,
  refreshCallback,
  shouldPaginate = false,
}: ITableProps) => {
  // @ts-ignore
  window.DateTime = DateTime;
  // @ts-ignore
  window.XLSX = XLSX;

  const tableRef = useRef<HTMLDivElement>(null);
  Tabulator.extendModule('accessor', 'accessors', {
    boolAsYesNo: boolAsYesNo,
    stringArrayAsCommaValues: stringArrayAsCommaValues,
    objectArrayToCsv: objectArrayToCsv,
  });

  /**
   * can't use useState for table filters as that triggers a tabulator data get
   */
  let tableFilters: { [key: string]: any } = {};

  const dataLoadError = (error: Response) => {
    if (error.status === 401) {
      navigateToLogout();
    }
  };

  let tabulatorRef = useRef();

  const tabulatorOpts = merge(
    {
      height: '100%',
      placeholder: 'No data available',
      //fit columns to width of table
      layout: 'fitColumns',
      // persist sort, filter and column widths in local storage
      persistence: {
        sort: false,
        // How can we get persisted filter values?
        filter: false,
      },
      persistenceID: id + '_tabulator_table',
      // send sort data to the server instead of processing locally
      // sortMode:"remote",
      progressiveLoad: shouldPaginate ? 'load' : null,
      progressiveLoadDelay: shouldPaginate ? 100 : null,
      progressiveLoadScrollMargin: shouldPaginate ? 10 : null,
      exportable: false,
      paginationSize: shouldPaginate ? 100 : null,
      ajaxConfig: {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
        },
      },
      events: {
        dataLoadError: dataLoadError,
      },
    },
    config
  );

  // Map ajax response to contain pagination information required by Tabulator
  if (shouldPaginate) {
    tabulatorOpts.ajaxResponse = function (
      url: string,
      params: object,
      response: ILaravelPaginator
    ) {
      return {
        data: response.data,
        ...response.meta,
        last_row: response.meta.total,
      };
    };
  } else {
    tabulatorOpts.ajaxResponse = function (
      url: string,
      params: object,
      response: { data: any[] }
    ) {
      return response.data;
    };
  }

  useEffect(() => {
    // @ts-ignore
    tabulatorRef.current = new Tabulator(tableRef.current, tabulatorOpts);
    if (tabulatorOpts.events) {
      Object.keys(tabulatorOpts.events).forEach((eventName: string) => {
        const handler = tabulatorOpts.events[eventName];
        (tabulatorRef.current as any).on(eventName, handler);
      });

      if (forwardedRef) {
        //@ts-ignore
        forwardedRef.current = tabulatorRef.current;
      }
    }

    // @ts-ignore
    window.Tabulator = tabulatorRef.current;
    // eslint-disable-next-line
  }, [tableRef, updated, tabulatorOpts?.data]);

  const buildSelectFilter = (
    value: any,
    filterName: string,
    filters: Array<any>
  ) => {
    const userFilter: any = [];
    filters.map(filter => {
      let filterValue = value;

      if (Array.isArray(value)) {
        // multiselect values
        filterValue = value.map(item => item.value);

        if (filter.type !== 'in') {
          filterValue = value.map(item => item.value).join(' ');
        }
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        filterValue = value.value;

        if (filter.type === 'in') {
          filterValue = [filterValue];
        }
      }

      if (filterValue.length > 0) {
        userFilter.push({ ...filter, value: filterValue });
      }
      return true;
    });

    tableFilters[filterName] = userFilter;

    applyTableFilters();
  };

  const buildInputFilter = (
    value: string,
    filterName: string,
    filters: Array<any>
  ) => {
    const orFilters = filters.length > 1;
    const userFilter: any = [];
    filters.map(filter => {
      userFilter.push({ ...filter, value: value });
      return true;
    });

    if (orFilters) {
      tableFilters[filterName] = [userFilter];
    } else {
      tableFilters[filterName] = userFilter;
    }

    applyTableFilters();
  };

  const applyTableFilters = () => {
    const filters: any = [];
    Object.values(tableFilters).map((f: any) => {
      filters.push(...f);
      return true;
    });

    // @ts-ignore
    tabulatorRef.current.clearFilter();
    // @ts-ignore
    tabulatorRef.current.setFilter(filters);
  };

  const downloadXlsx = () => {
    // @ts-ignore
    tabulatorRef.current.download('xlsx', id + '.xlsx');
  };

  const refreshData = () => {
    // @ts-ignore
    tabulatorRef.current.setData();
  };

  const getFilters = () => {
    if (
      !tabulatorOpts.tableFilters ||
      tabulatorOpts.tableFilters.length === 0
    ) {
      return <></>;
    }

    return (
      <div className="w-full">
        <div className="flex flex-col items-center gap-2 lg:flex-row">
          {tabulatorOpts.exportable && (
            <div className="py-4">
              <Button
                onClick={() => downloadXlsx()}
                icon={faFileArrowDown}
                size={'sm'}
              >
                Download
              </Button>
            </div>
          )}

          {tabulatorOpts.tableFilters.map((filter: IFilterProps) => {
            if (filter.type === 'dropdown') {
              return (
                <div
                  key={filter.filterName + '_filter_container'}
                  className={
                    filter.filterClass
                      ? filter.filterClass
                      : 'min-w-[15%] w-full lg:w-[20%]'
                  }
                >
                  <Select
                    placeHolder={
                      filter.placeholder ? filter.placeholder : 'Select'
                    }
                    isSearchable={filter.searchable}
                    isMulti={filter.multiselect}
                    options={
                      filter.options && filter.options.length > 0
                        ? filter.options
                        : []
                    }
                    onChange={value =>
                      buildSelectFilter(
                        value,
                        filter.filterName,
                        filter.filterOn
                      )
                    }
                  />
                </div>
              );
            }

            if (filter.type === 'refresh') {
              return (
                <div className="py-4">
                  <Button
                    onClick={refreshCallback ? refreshCallback : refreshData}
                    icon={faArrowsRotate}
                    size={'sm'}
                  >
                    {filter.placeholder}
                  </Button>
                </div>
              );
            }

            if (filter.type === 'input') {
              return (
                <div
                  key={filter.filterName + '_filter_container'}
                  className="w-full lg:w-[40%] ml-auto"
                >
                  <Input
                    placeHolder={
                      filter.placeholder ? filter.placeholder : 'Search'
                    }
                    onChange={value =>
                      buildInputFilter(
                        value,
                        filter.filterName,
                        filter.filterOn
                      )
                    }
                    data-testing="search-bar"
                  />
                </div>
              );
            }
            return null;
          })}
          {actions && <div className="py-4 m-auto lg:m-0">{actions}</div>}
        </div>
        {aboveTable && <div>{aboveTable}</div>}
      </div>
    );
  };

  return (
    <>
      {getFilters()}

      <div
        className={cn(
          'striped table-component',
          tabulatorOpts?.position === 'absolute' ? 'absolute' : ''
        )}
        ref={tableRef}
      ></div>
    </>
  );
};
export default TabulatorTable;
