import React from 'react';
import { Node } from 'reactflow';
import { CustomNodeData, CustomNodeType } from './types/node-types';
import Icon from '../../../../components/common/Icon';
import Sizes from '../../../../types/enums/common/Sizes';
import ApiNodeFormFields from './nodes/form-fields/ApiNodeFormFields';
import SpreadsheetNodeFormFields from './nodes/form-fields/SpreadsheetNodeFormFields';
import BorealisNodeFormFields from './nodes/form-fields/BorealisNodeFormFields';
import DropKeysFormFields from './nodes/form-fields/DropKeysFormFields';
import FieldMapFormFields from './nodes/form-fields/FieldMapFormFields';
import ValueMapFormFields from '@/pages/dashboard/projects/graph/nodes/form-fields/ValueMapFormFields';
import CustomFunctionFormFields from './nodes/form-fields/CustomFunctionFormFields';
import { StorageNodeFormFields } from './nodes/form-fields/StorageNodeFormFields';
import DateTimeFormFields from '@/pages/dashboard/projects/graph/nodes/form-fields/DateTimeFormFields';
import LocationFormFields from '@/pages/dashboard/projects/graph/nodes/form-fields/LocationFormFields';
import SentimentFormFields from '@/pages/dashboard/projects/graph/nodes/form-fields/SentimentFormFields';
import SummarizeFormFields from '@/pages/dashboard/projects/graph/nodes/form-fields/SummarizeFormFields';
import TagFormFields from '@/pages/dashboard/projects/graph/nodes/form-fields/TagFormFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { JoinNodeFormFields } from './nodes/form-fields/JoinNodeFormFields';
import FilterFormFields from '@/pages/dashboard/projects/graph/nodes/form-fields/FilterFormFields';
import { Alert, AlertDescription, AlertTitle } from '@tcomponents/ui/alert';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { IconButton } from '@/tcomponents/ui/button';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useCustomGraphContext } from './CustomGraphProvider';
import { ProcessNodeFormFields } from './nodes/form-fields/ProcessNodeFormFields';
import EndpointNodeFormFields from '@/pages/dashboard/projects/graph/nodes/form-fields/EndpointNodeFormFields';
import SenderNodeFormFields from '@/pages/dashboard/projects/graph/nodes/form-fields/SenderNodeFormFields';
import ReceiverNodeFormFields from '@/pages/dashboard/projects/graph/nodes/form-fields/ReceiverNodeFormFields';
import InternalGeneratorNodeFormFields from '@/pages/dashboard/projects/graph/nodes/form-fields/InternalGeneratorNodeFormFields';

type PipelineStateHandling = {
  toggleNewSubGraph: (value: boolean) => void;
  isSubGraphActive: boolean;
  subGraphParentNode?: Node<CustomNodeData>;
};

type FormViewSettings = {
  toggleExpanded: { callback: () => void };
  nodeErrors: {
    title: string;
    message: string;
  };
};

const ConfigurationForm = (props: PipelineStateHandling & FormViewSettings) => {
  const {
    updateNodeData,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();

  const handleHeadingChange = (val: string) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.heading = val;
    updateNodeData(dataCopy);
  };

  const { heading, isError } = activeNode.data;
  const { toggleNewSubGraph } = props;

  // function to handle error dismissal, it should reset the state, isError, error title and error message properties
  const handleErrorDismissal = (id: string) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.isError = false;
    dataCopy.error_title = '';
    dataCopy.error_message = '';
    dataCopy.state = 'Ready';
    updateNodeData(dataCopy);
  };

  return (
    <div className="absolute top-0 bottom-0 flex flex-col w-full overflow-auto bg-gray-50">
      <div
        style={{
          backgroundColor: `${activeNode.data.meta?.bgColor}`,
        }}
        className="flex items-center justify-between gap-1 p-1"
      >
        <div className="flex items-center gap-x-2">
          <FontAwesomeIcon
            icon={activeNode.data.meta?.icon}
            className="text-xl text-white"
          />
          <input
            style={{
              backgroundColor: `${activeNode.data.meta?.bgColor}`,
            }}
            className="text-white text-sm font-bold outline-white border border-white bg-transparent p-[2px] selection:text-white rounded-sm"
            type="text"
            value={heading}
            onChange={e => handleHeadingChange(e.target.value)}
          />
        </div>
        {activeNode.data.type === CustomNodeType.Process ? (
          <button
            className={'hover:opacity-60 hover:cursor-pointer'}
            onClick={() => toggleNewSubGraph(true)}
          >
            <Icon
              size={Sizes.LARGE}
              icon={'fas fa-up-right-and-down-left-from-center'}
              className="text-white"
            />
          </button>
        ) : (
          <button
            className={'hover:opacity-60 hover:cursor-pointer'}
            onClick={() => props.toggleExpanded.callback()}
          >
            <Icon
              size={Sizes.LARGE}
              icon={
                isExpanded
                  ? 'fa-solid fa-down-left-and-up-right-to-center'
                  : 'fas fa-up-right-and-down-left-from-center'
              }
              className="text-white"
            />
          </button>
        )}
      </div>
      {activeNode.data.type !== CustomNodeType.Endpoint && (
        <div className="relative flex items-center w-full border-b gap-x-1 bg-[#e3e3e3]">
          <span className="pt-1 pb-1 pl-2 font-bold">Last Successful Run:</span>
          <span className="pt-1 pb-1">
            {activeNode.last_successful_run ?? 'N/A'}
          </span>
        </div>
      )}
      {isError && (
        <div className="relative flex items-center w-full border-b bg-danger gap-x-2">
          <Alert className="flex w-full overflow-auto rounded-none bg-danger-background max-h-80">
            <div className="mr-5">
              <FontAwesomeIcon
                className="w-8 h-8 text-danger-error"
                icon={faCircleExclamation}
              />
            </div>
            <div className="w-[calc(100%-4rem)]">
              <AlertTitle className="text-lg font-bold break-all text-danger-foreground">
                {props.nodeErrors.title}
              </AlertTitle>
              <AlertDescription className="text-base break-all text-danger-foreground">
                {props.nodeErrors.message}
              </AlertDescription>
            </div>
            <IconButton
              tooltip="Dismiss Error"
              className="absolute w-5 h-5 top-1 right-1 text-danger-foreground"
              icon={faXmark}
              onClick={() => handleErrorDismissal(activeNode.id)}
            />
          </Alert>
        </div>
      )}
      {activeNode.data.type === CustomNodeType.Api && <ApiNodeFormFields />}
      {activeNode.data.type === CustomNodeType.Endpoint && (
        <EndpointNodeFormFields />
      )}
      {activeNode.data.type === CustomNodeType.Spreadsheet && (
        <SpreadsheetNodeFormFields />
      )}
      {activeNode.data.type === CustomNodeType.Borealis && (
        <BorealisNodeFormFields />
      )}
      {activeNode.data.type === CustomNodeType.DropKeys && (
        <DropKeysFormFields />
      )}
      {activeNode.data.type === CustomNodeType.FieldMap && (
        <FieldMapFormFields />
      )}
      {activeNode.data.type === CustomNodeType.ValueMap && (
        <ValueMapFormFields />
      )}
      {activeNode.data.type === CustomNodeType.CustomFunction && (
        <CustomFunctionFormFields />
      )}
      {activeNode.data.type === CustomNodeType.Storage && (
        <StorageNodeFormFields subGraphParentNode={props.subGraphParentNode} />
      )}
      {activeNode.data.type === CustomNodeType.Join && <JoinNodeFormFields />}
      {activeNode.data.type === CustomNodeType.Process && (
        <ProcessNodeFormFields />
      )}
      {activeNode.data.type === CustomNodeType.DateTime && (
        <DateTimeFormFields />
      )}
      {activeNode.data.type === CustomNodeType.Location && (
        <LocationFormFields />
      )}
      {activeNode.data.type === CustomNodeType.Sentiment && (
        <SentimentFormFields />
      )}
      {activeNode.data.type === CustomNodeType.Summarize && (
        <SummarizeFormFields />
      )}
      {activeNode.data.type === CustomNodeType.Tag && <TagFormFields />}
      {activeNode.data.type === CustomNodeType.Filter && <FilterFormFields />}
      {activeNode.data.type === CustomNodeType.Sender && (
        <SenderNodeFormFields />
      )}
      {activeNode.data.type === CustomNodeType.Receiver && (
        <ReceiverNodeFormFields />
      )}
      {activeNode.data.type === CustomNodeType.InternalGenerator && (
        <InternalGeneratorNodeFormFields />
      )}
    </div>
  );
};

export default ConfigurationForm;
