import { FormEvent, MouseEvent, ReactElement } from 'react';
import { Input } from '@tcomponents/ui/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Mappable from '@/pages/dashboard/projects/graph/types/mappable';

interface Props {
  field: Mappable;
  onChange: (field: Mappable) => void;
  onDelete: (event: MouseEvent<HTMLButtonElement>) => void;
}

const Field = ({ field, onChange, onDelete }: Props): ReactElement => {
  const updateInput = (event: FormEvent<HTMLInputElement>) =>
    onChange({
      ...field,
      input: (event.target as HTMLInputElement).value,
    });

  const updateOutput = (event: FormEvent<HTMLInputElement>) =>
    onChange({
      ...field,
      output: (event.target as HTMLInputElement).value,
    });

  return (
    <div className="grid grid-cols-[repeat(2,_minmax(0,_1fr))_40px] gap-2 border-b pb-2">
      <Input value={field.input} onInput={updateInput} />

      <Input value={field.output} onInput={updateOutput} />

      <button
        className="bg-red-500 rounded text-white flex items-center justify-center"
        onClick={onDelete}
      >
        <FontAwesomeIcon icon={faXmark} />
      </button>
    </div>
  );
};

export default Field;
