import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { useMap } from 'react-leaflet';
import L, { Control } from 'leaflet';
import { useMapViewContext } from '../mapContext/MapViewProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassArrowsRotate } from '@fortawesome/pro-solid-svg-icons';

const ResetZoomControl = () => {
  const map = useMap();
  const { initialMapBoundsOnLoad } = useMapViewContext();

  useEffect(() => {
    if (!map) return;

    const control = new Control({ position: 'topright' });

    control.onAdd = () => {
      const div = L.DomUtil.create(
        'div',
        'border-2 p-2 rounded-sm hover:bg-gray-100 cursor-pointer bg-white leaflet-bar leaflet-control'
      );
      div.title = 'Restore zoom';
      div.onclick = () => {
        if (initialMapBoundsOnLoad) {
          map.fitBounds(initialMapBoundsOnLoad);
        }
      };

      const button = (
        <FontAwesomeIcon icon={faMagnifyingGlassArrowsRotate} size="xl" />
      );

      // Using createRoot to render the button into the div
      const root = createRoot(div);
      root.render(button);

      return div;
    };

    control.addTo(map);

    return () => {
      control.remove();
    };
  }, [map, initialMapBoundsOnLoad]);

  return null;
};

export default ResetZoomControl;
