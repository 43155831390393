import { useRef, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@tcomponents/ui/card';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/tcomponents/ui/popover';
import { Input } from '@tcomponents/ui/input';
import { Textarea } from '@tcomponents/ui/textarea';
import { useSurveyContext } from '../SurveyProvider';
import { useSaveActionContext } from '@/context/SaveActionContext';
import { Label } from '@/tcomponents/ui/label';
import { Switch } from '@/tcomponents/ui/switch';
import { Button } from '@/tcomponents/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { PopoverClose } from '@radix-ui/react-popover';

const EmailTab = () => {
  const { formContent, setFormContent } = useSurveyContext();
  const { setIsDirty } = useSaveActionContext();
  const [cursorPosition, setCursorPosition] = useState(0);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const selectedQuestions =
    formContent.steps.filter(step => step.step === 'question') ?? [];

  const insertFieldItems = selectedQuestions
    .map(item => {
      // for form type questions
      if (item.type === 'form') {
        return item.fields.map(field => field.key);
        // for grid type questions
      } else if (item.type === 'grid') {
        return item.topics.map(topic => topic.key);
        // any other type of question
      } else if ('key' in item) {
        return item.key;
      } else {
        return null;
      }
    })
    .flat()
    .filter(Boolean) as string[];

  const handleInputChange =
    (field: 'subject' | 'to' | 'bcc') =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const obj = { ...formContent };
      obj.email[field] = e.target.value;
      setFormContent(obj);
      setIsDirty(true);
    };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const obj = { ...formContent };
    obj.email.body = e.target.value;
    setFormContent(obj);
    setIsDirty(true);
    setCursorPosition(e.target.selectionStart);
  };

  const handleTextareaClick = (e: React.MouseEvent<HTMLTextAreaElement>) => {
    setCursorPosition(e.currentTarget.selectionStart);
  };

  const handleDropdownSelection = (
    value: string,
    type: 'field' | 'placeholder'
  ) => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const position = cursorPosition;
      const wrappedValue = type === 'field' ? `{{${value}}}` : `[${value}]`;
      textarea.setRangeText(wrappedValue, position, position, 'end');
      const obj = { ...formContent };
      obj.email.body = textarea.value;
      setFormContent(obj);
      setIsDirty(true);
      setCursorPosition(position + wrappedValue.length);
      requestAnimationFrame(() => {
        textarea.focus();
        textarea.setSelectionRange(
          position + wrappedValue.length,
          position + wrappedValue.length
        );
      });
    }
  };

  const insertPlaceholderItems = [
    'Insert comment here',
    'Insert supportive comment here',
    'Insert additional supportive comment here',
    'Insert your name here',
    'Please write your own reason for supporting the application here before pressing send',
  ];

  return (
    <Card className="h-fit">
      <CardHeader className="flex-row gap-x-4">
        <div>
          <CardTitle>User Email</CardTitle>
          <h6 className="mt-1 text-gray-400">
            Generate an email for the user to send from their own email cient
          </h6>
        </div>
        <div className="!m-0">
          <Label>Enabled</Label>
          <Switch
            checked={formContent.email.enabled}
            onCheckedChange={val => {
              const obj = { ...formContent };
              obj.email.enabled = val;
              setFormContent(obj);
              setIsDirty(true);
            }}
          />
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex flex-col gap-y-2">
            <Label>Subject</Label>
            <Input
              value={formContent.email.subject}
              onChange={handleInputChange('subject')}
            />
          </div>

          <div className="flex gap-x-4">
            <div className="flex flex-col w-1/2 gap-y-2">
              <Label>To</Label>
              <Input
                value={formContent.email.to}
                onChange={handleInputChange('to')}
              />
            </div>
            <div className="flex flex-col w-1/2 gap-y-2">
              <Label>BCC</Label>
              <Input
                value={formContent.email.bcc}
                onChange={handleInputChange('bcc')}
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <Label>Body Content</Label>
            <div className="border rounded-md bg-background">
              <div className="flex w-full h-[50px] items-center px-2 bg-gray-300 gap-x-4">
                <Popover>
                  <PopoverTrigger>
                    <Button size={'sm'}>
                      {'{{insert field}}'}
                      <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    side="bottom"
                    align="start"
                    className="p-0 text-[14px] w-fit max-w-[500px]"
                  >
                    <PopoverClose className="flex flex-col text-left">
                      <div className="flex flex-col divide-y divide-gray-100">
                        {insertFieldItems.map((item: string, idx: number) => (
                          <Button
                            key={idx}
                            variant={'ghost'}
                            onClick={() => {
                              handleDropdownSelection(item, 'field');
                            }}
                            className="font-normal text-[14px] text-gray-800 px-4 justify-start text-left min-w-full whitespace-normal h-fit"
                          >
                            {item}
                          </Button>
                        ))}
                      </div>
                    </PopoverClose>
                  </PopoverContent>
                </Popover>
                <Popover>
                  <PopoverTrigger>
                    <Button size={'sm'}>
                      {'[insert placeholder]'}
                      <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    side="bottom"
                    align="start"
                    className="p-0 text-[14px] w-fit max-w-[500px]"
                  >
                    <PopoverClose className="flex flex-col text-left">
                      <div className="flex flex-col divide-y divide-gray-100">
                        {insertPlaceholderItems.map(
                          (item: string, idx: number) => (
                            <Button
                              key={idx}
                              variant="ghost"
                              onClick={() => {
                                handleDropdownSelection(item, 'placeholder');
                              }}
                              className="font-normal text-[14px] text-gray-800 px-4 justify-start text-left min-w-full whitespace-normal h-fit"
                            >
                              {item}
                            </Button>
                          )
                        )}
                      </div>
                    </PopoverClose>
                  </PopoverContent>
                </Popover>
              </div>
              <Textarea
                ref={textareaRef}
                rows={20}
                value={formContent.email.body}
                onChange={handleTextareaChange}
                onClick={handleTextareaClick}
              />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default EmailTab;
