import React, { ReactElement } from 'react';
import { CustomNode } from '.';
import { Handle, Node, Position } from 'reactflow';
import { FieldMapNodeData } from '../types/node-types';

const FieldMapNode = ({
  data,
  ...rest
}: Node<FieldMapNodeData>): ReactElement => (
  <CustomNode data={data} nodeProps={rest}>
    <div className="p-1 font-bold text-center text-gray-600">
      <p className="text-xs">Fields Mapped: {data.fields.length}</p>
    </div>

    <Handle
      type="target"
      position={Position.Top}
      id="b"
      style={{
        background: '#fff',
        border: '1px solid gray',
        width: '8px',
        height: '8px',
      }}
    />

    <Handle
      type="source"
      position={Position.Bottom}
      id="a"
      style={{
        background: '#fff',
        border: '1px solid gray',
        width: '8px',
        height: '8px',
      }}
    />
  </CustomNode>
);
export default FieldMapNode;
