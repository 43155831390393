import React from 'react';

import { CustomNodeRenderProps, CustomNodeType } from './types/node-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowProgress,
  faArrowRightFromArc,
  faArrowRightToArc,
  faCalendarDays,
  faCloudBinary,
  faCode,
  faDatabase,
  faFaceSmile,
  faFileSpreadsheet,
  faFilter,
  faIndustryWindows,
  faKey,
  faRectangleList,
  faRightLeft,
  faTags,
  faSwapArrows,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faLocationDot,
  faRightToBracket,
  faSitemap,
} from '@fortawesome/free-solid-svg-icons';

type RenderItemProps = CustomNodeRenderProps;
const inputNodeTypes: RenderItemProps[] = [
  {
    type: CustomNodeType.Api,
    icon: faCloudBinary,
    color: '#DB2828',
    title: 'API',
  },
  {
    type: CustomNodeType.Endpoint,
    icon: faRightToBracket,
    color: '#DB2828',
    title: 'Endpoint',
  },
  {
    type: CustomNodeType.Spreadsheet,
    icon: faFileSpreadsheet,
    color: '#DB2828',
    title: 'Spreadsheet',
  },
  {
    type: CustomNodeType.Borealis,
    icon: faDatabase,
    color: '#DB2828',
    title: 'Borealis',
  },
];
const processingNodeTypes: RenderItemProps[] = [
  {
    type: CustomNodeType.Process,
    icon: faArrowProgress,
    color: '#265F81',
    title: 'PROCESS',
  },
  {
    type: CustomNodeType.Join,
    icon: faSitemap,
    color: '#FBBD08',
    title: 'JOIN',
  },
];

const dataTransferNodeTypes: RenderItemProps[] = [
  {
    type: CustomNodeType.Sender,
    icon: faArrowRightFromArc,
    color: '#A0D1ED',
    title: 'Sender',
  },
  {
    type: CustomNodeType.Receiver,
    icon: faArrowRightToArc,
    color: '#A0D1ED',
    title: 'Receiver',
  },
];
const helperNodeTypes: RenderItemProps[] = [
  {
    type: CustomNodeType.DropKeys,
    icon: faKey,
    color: '#DB2828',
    title: 'Drop Keys',
  },
  {
    type: CustomNodeType.Filter,
    icon: faFilter,
    color: '#265F81',
    title: 'Filter',
  },
  {
    type: CustomNodeType.Location,
    icon: faLocationDot,
    color: '#A0D1ED',
    title: 'Location',
  },
  {
    type: CustomNodeType.DateTime,
    icon: faCalendarDays,
    color: '#F07A2C',
    title: 'DateTime',
  },
  {
    type: CustomNodeType.InternalGenerator,
    icon: faIndustryWindows,
    color: '#FBBD08',
    title: 'Generator',
  },
  {
    type: CustomNodeType.FieldMap,
    icon: faSwapArrows,
    color: '#FBBD08',
    title: 'Field Map',
  },
  {
    type: CustomNodeType.ValueMap,
    icon: faRightLeft,
    color: '#FBE308',
    title: 'Value Map',
  },
];
const customNodeTypes: RenderItemProps[] = [
  {
    type: CustomNodeType.CustomFunction,
    icon: faCode,
    color: 'green',
    title: 'Custom',
  },
];
const aiNodeTypes: RenderItemProps[] = [
  {
    type: CustomNodeType.Tag,
    icon: faTags,
    color: '#C84BBC',
    title: 'Tag',
  },
  {
    type: CustomNodeType.Sentiment,
    icon: faFaceSmile,
    color: '#C84BBC',
    title: 'Sentiment',
  },
  {
    type: CustomNodeType.Summarize,
    icon: faRectangleList,
    color: '#C84BBC',
    title: 'Summarize',
  },
];

type GraphSidebarProps = {
  isSubGraphActive?: boolean;
};

export default function GraphSidebar(props: GraphSidebarProps) {
  // @ts-ignore
  const onDragStart = (event, item: RenderItemProps) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify(item));
    event.dataTransfer.effectAllowed = 'move';
  };

  function renderItem(item: RenderItemProps) {
    const { type, icon, color, title } = item;
    return (
      <div
        key={type}
        onDragStart={event => onDragStart(event, item)}
        draggable
        className="flex flex-col items-center justify-between font-bold text-gray-600 gap-x-4 border p-2 m-2 w-[70px] h-[70px] rounded-xl border-gray-500"
        style={{ backgroundColor: `${color}` }}
      >
        <FontAwesomeIcon icon={icon} className="text-4xl text-white" />
        <p className="text-xs text-white text-center">{title}</p>
      </div>
    );
  }

  return (
    <aside className="border shadow w-[250px] xl:w-[300px] flex flex-col bg-white">
      <h5 className="p-4 font-bold text-gray-600 border-b">Components</h5>

      {props.isSubGraphActive ? (
        <div>
          <h5 className="px-4 py-3 font-bold bg-gray-100 ">Helpers</h5>
          <div className="flex flex-wrap p-4 gap-x-2">
            {helperNodeTypes.map(renderItem)}
          </div>
          <h5 className="px-4 py-3 font-bold bg-gray-100 ">AI</h5>
          <div className="flex flex-wrap p-4 gap-x-2">
            {aiNodeTypes.map(renderItem)}
          </div>
          <h5 className="px-4 py-3 font-bold bg-gray-100 ">Custom</h5>
          <div className="flex flex-wrap p-4 gap-x-2">
            {customNodeTypes.map(renderItem)}
          </div>
        </div>
      ) : (
        <div>
          <div className="flex items-center justify-around">
            <div className="px-4 py-2 border-b-2 border-green-700">
              <div className="font-bold ">New</div>
            </div>
            <div className="">Existing</div>
          </div>
          <div className="">
            <h5 className="px-4 py-3 font-bold bg-gray-100 ">Sources</h5>
            <div className="flex p-4 gap-x-2 flex-wrap">
              {inputNodeTypes.map(renderItem)}
            </div>
            <h5 className="px-4 py-3 font-bold bg-gray-100 ">Processing</h5>
            <div className="flex p-4 gap-x-2 flex-wrap">
              {processingNodeTypes.map(renderItem)}
            </div>
            <h5 className="px-4 py-3 font-bold bg-gray-100 ">Data Transfer</h5>
            <div className="flex p-4 gap-x-2 flex-wrap">
              {dataTransferNodeTypes.map(renderItem)}
            </div>
          </div>
        </div>
      )}
    </aside>
  );
}
