import { FormConfiguration, iFormState } from './Types';

const defaultSurveyFormState: iFormState = {
  visibility: 'draft',
  setup: {
    website_setup: {
      website_url: '',
      package: '',
      preview_password: '',
      theme_color: '#005f85',
    },
    browser_tab: {
      icon: '',
      title: '',
    },
    social_share: {
      image: '',
      title: '',
      description: '',
    },
    borealis_integration: {
      engagement_plan_id: '',
    },
  },
  welcome: {
    page_content: {
      image: '',
      title: '',
      description: '',
    },
    data_protection_details: {
      client_name: '',
      client_email: '',
      client_phone: '',
      client_privacy: '',
    },
  },
  email: {
    enabled: false,
    subject: '',
    to: '',
    bcc: '',
    body: '',
  },
  submit: {
    policy_links: {
      privacy_policy: '',
      terms_and_conditions: '',
    },
    page_content: {
      image: '',
      title: '',
      statement: '',
    },
    agreements: [],
  },
  thanks: {
    page_content: {
      title: '',
      statement: '',
    },
    find_out_more: {
      link_url: '',
      statement: '',
      share: true,
    },
  },
  steps: [],
};

const mapFormStateToConfiguration = (
  form: iFormState,
  id: number | null
): FormConfiguration => {
  return {
    id: id,
    url: form.setup.website_setup.website_url,
    package_id: form.setup.website_setup.package,
    preview_password: form.setup.website_setup.preview_password,
    theme_color: form.setup.website_setup.theme_color,
    favicon: form.setup.browser_tab.icon,
    browser_tab_title: form.setup.browser_tab.title,
    social_share_image: form.setup.social_share.image,
    social_share_title: form.setup.social_share.title,
    social_share_description: form.setup.social_share.description,
    borealis_engagement_plan_id:
      form.setup.borealis_integration.engagement_plan_id,
    welcome_image: form.welcome.page_content.image,
    welcome_page_title: form.welcome.page_content.title,
    welcome_description: form.welcome.page_content.description,
    client_name: form.welcome.data_protection_details.client_name,
    client_email: form.welcome.data_protection_details.client_email,
    client_phone: form.welcome.data_protection_details.client_phone,
    client_privacy: form.welcome.data_protection_details.client_privacy,
    privacy_policy: form.submit.policy_links.privacy_policy,
    terms_and_conditions: form.submit.policy_links.terms_and_conditions,
    submit_image: form.submit.page_content.image,
    submit_title: form.submit.page_content.title,
    submit_description: form.submit.page_content.statement,
    thanks_title: form.thanks.page_content.title,
    thanks_statement: form.thanks.page_content.statement,
    find_out_more_statement: form.thanks.find_out_more.statement,
    find_out_more_link: form.thanks.find_out_more.link_url,
    share: form.thanks.find_out_more.share,
    agreements: form.submit.agreements,
    steps: form.steps,
    email_enabled: form.email.enabled,
    email_subject: form.email.subject,
    email_to: form.email.to,
    email_bcc: form.email.bcc,
    email_body: form.email.body,
  };
};
const mapConfigurationToFormState = (feature: any): iFormState => {
  return {
    visibility: feature.visibility,
    setup: {
      website_setup: {
        website_url: feature.url ?? '',
        package: feature.survey_package?.id ?? '',
        preview_password: feature.preview_password ?? '',
        theme_color: feature.theme_color ?? '#005f85',
      },
      browser_tab: {
        icon: feature.favicon ?? '',
        title: feature.browser_tab_title ?? '',
      },
      social_share: {
        image: feature.social_share_image ?? '',
        title: feature.social_share_title ?? '',
        description: feature.social_share_description ?? '',
      },
      borealis_integration: {
        engagement_plan_id: feature.borealis_engagement_plan_id ?? '',
      },
    },
    welcome: {
      page_content: {
        image: feature.welcome_image ?? '',
        title: feature.welcome_title ?? '',
        description: feature.welcome_description ?? '',
      },
      data_protection_details: {
        client_name: feature.client_name,
        client_email: feature.client_email,
        client_phone: feature.client_phone,
        client_privacy: feature.client_privacy,
      },
    },
    email: {
      enabled: feature.email_enabled ?? false,
      subject: feature.email_subject ?? '',
      to: feature.email_to ?? '',
      bcc: feature.email_bcc ?? '',
      body: feature.email_body ?? '',
    },
    submit: {
      policy_links: {
        privacy_policy:
          feature.privacy_policy ?? 'https://cavendishconsulting.com/privacy',
        terms_and_conditions: feature.terms_and_conditions ?? '',
      },
      page_content: {
        image: feature.submit_image ?? '',
        title: feature.submit_title ?? 'Submit your answers',
        statement: feature.submit_description ?? '',
      },
      agreements: feature.agreements ?? [],
    },
    steps: feature.steps ?? [],
    thanks: {
      page_content: {
        title: feature.thanks_title ?? 'Thanks for taking part',
        statement:
          feature.thanks_statement ??
          'Your feedback will help us shape the future of this project',
      },
      find_out_more: {
        statement: feature.find_out_more_statement ?? '',
        link_url: feature.find_out_more_link ?? '',
        share: feature.share ?? false,
      },
    },
  };
};

export {
  defaultSurveyFormState,
  mapConfigurationToFormState,
  mapFormStateToConfiguration,
};
