export const rolePermissions = {
  general: {
    read: 'role.general.read',
    create: 'role.general.create',
    edit: 'role.general.edit',
    restrict: 'role.general.restrict',
    delete: 'role.general.delete',
  },
} as const;

export const userPermissions = {
  general: {
    read: 'user.general.read',
    create: 'user.general.create',
    edit: 'user.general.edit',
    disable: 'user.general.disable',
    password_reset: 'user.general.password_reset',
  },
  role_management: {
    assign_roles: 'user.role_management.assign_roles',
    assign_restricted_roles: 'user.role_management.assign_restricted_roles',
  },
  system_administration: {
    audit_trail: 'user.system_administration.audit_trail',
  },
} as const;

export const projectPermissions = {
  general: {
    create_new: 'project.general.create_new',
    reassign_type: 'project.general.reassign_type',
    create_from_template: 'project.general.create_from_template',
    template: 'project.general.template',
    review: 'project.general.review',
    settings_access: 'project.general.settings_access',
    archive: 'project.general.archive',
    delete: 'project.general.delete',
    read_unpublished: 'project.general.read_unpublished',
  },
  system_administration: {
    manage_projects: 'project.system_administration.manage_projects',
  },
} as const;

export const workflowPermissions = {
  general: {
    edit_data: 'workflow.general.edit_data',
    schema_settings: 'workflow.general.schema_settings',
  },
  system_administration: {
    monitor: 'workflow.system_administration.monitor',
    integrations: 'workflow.system_administration.integrations',
  },
} as const;

export const surveyPermissions = {
  general: {
    edit: 'surveys.general.edit_questions',
    approve: 'surveys.general.approve',
  },
  system_administration: {
    manage_packages: 'surveys.system_administration.manage_packages',
  },
} as const;

export const aiWizardPermissions = {
  general: {
    read: 'ai_wizard.general.read',
    create: 'ai_wizard.general.create',
    settings_access: 'ai_wizard.general.settings_access',
    delete: 'ai_wizard.general.delete',
  },
  system_administration: {
    monitor: 'ai_wizard.system_administration.monitor',
  },
} as const;

export const mapLayersPermissions = {
  general: {
    read: 'map_layers.general.read',
    create: 'map_layers.general.create',
    edit: 'map_layers.general.edit',
    delete: 'map_layers.general.delete',
  },
} as const;

export const featurePermissions = {
  general: {
    read: 'feature.general.read',
    create: 'feature.general.create',
    settings_access: 'feature.general.settings_access',
    delete: 'feature.general.delete',
  },
} as const;

export const servicePermissions = {
  print_and_post: {
    read: 'services.print_and_post.read',
  },
  address_lookup: {
    read: 'services.address_lookup.read',
  },
} as const;

export const organisationPermissions = {
  general: {
    read: 'organisation.general.read',
    create: 'organisation.general.create',
    edit: 'organisation.general.edit',
    delete: 'organisation.general.delete',
  },
} as const;
